/**
 *  Main CSS file for EMR
 *  
 *  @author Mahesh Hegde, Sanjay, Soundary P, Arifa
 *  @version 1.0.0
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 */

@import "variables";
@import "mixins";
@import "layout";
@import "buttons";
@import "_text";
@import "input";
@import "tables";
@import "header";
@import "progress-wizard.scss";
@import "icons.scss";
@import "_timeline.scss";
@import "my-calendar";
@import "_bed.scss";
@import "forms.scss";
@import "ant-desing-style.scss";
@import "homecarecard.scss";
@import "homecarecommon.scss";
@import "progress.scss";
html,
body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    min-height: 100vh;
    color: $color_text_default;
}

* {
    font-family: "Roboto", sans-serif !important;
    outline: none !important;
}

a:hover {
    color: $primary;
}

.text-secondary {
    color: $primary !important;
}

.alert {
    padding: 0.75rem 1rem;
}

pre {
    white-space: pre-line;
}

.alert-dark {
    background-color: $color_text_default;
    color: $color_white;
    strong {
        font-weight: 400;
    }
}

.alert-danger {
    strong {
        font-weight: 500;
    }
}

.text-muted {
    color: $secondary !important;
}

.post-left {
    position: absolute;
    left: 0px;
}

.border-primary {
    border-color: $primary !important;
}

.border-5 {
    border-width: 5px !important;
}

.hide {
    display: none;
}

.bg {
    &-primary {
        background-color: $primary !important;
    }
    &-alert {
        background-color: #d24350 !important;
    }
}

.text-warning {
    color: $orange !important;
}

.position-absolute-right {
    position: absolute;
    right: 10px;
    top: 4px;
}

.img-logo {
    max-width: 160px;
}

.position-copy-from-template {
    position: absolute;
    right: 10px;
    top: -7px;
}

#main {
    min-height: calc(100vh - 135px);
    margin-left: auto;
    margin-right: auto;
}

.full-height {
    min-height: 100vh;
}

.font-weight-semibold {
    font-weight: 500;
}

.inline-checkbox {
    position: relative;
    top: -0.55rem;
}

.inline-input-other {
    width: 150px;
    display: inline-block !important;
}

#followup_other_block {
    display: none;
}

.text-medium {
    font-size: 0.9375rem;
}

.bg-lightgrey {
    background-color: $color_smoke_gray;
}

.bg-grey {
    background-color: $bg-grey;
}

.emeregency {
    background-color: #ffeaea;
    border-color: #fac3c3;
    // box-shadow: inset 0 -16px 16px 4px rgba(229, 185, 185, 0.33), 0 0 24px 4px rgba(211, 211, 211, .33) !important;
}

.virtual_consult {
    background-color: #fdf8e3;
    //  box-shadow: inset 0 -16px 16px 4px rgba(229, 185, 185, 0.33), 0 0 24px 4px rgba(211, 211, 211, .33) !important;
}

.otpinp_wrapper {
    position: relative;
    padding-right: 30px;
    box-sizing: border-box;
    &>div {
        justify-content: center;
        input {
            border-top-style: initial;
            border-right-style: initial;
            border-left-style: initial;
            border-top-color: initial;
            border-right-color: initial;
            border-left-color: initial;
            font-size: 25px;
            height: 41px !important;
            width: 70px !important;
            margin: 0px 20px;
            border-width: 0px 0px 2px;
            border-image: initial;
            border-bottom: 2px solid $primary;
            outline: none;
            padding-right: unset !important;
        }
    }
    .show_password {
        position: absolute;
        right: 0px;
        bottom: 0px;
        cursor: pointer;
    }
}

.Resend-otp {
    outline: none;
    border: none;
    background: none;
    // color: #666 !important;
    &:hover {
        color: $primary !important;
    }
}

.password_attribute {
    input[type="text"],
    input[type="password"] {
        padding-right: 40px;
    }
    .show_password {
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 99;
        width: 25px;
        height: calc(100% - 2px);
        text-align: center;
        i {
            font-style: normal;
            width: 18px;
            height: 100%;
            display: block;
            padding-top: 12px;
            cursor: pointer;
        }
        &::after {
            color: #666;
        }
        &.on {
            i {
                padding-top: 14px;
                &:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJleWUtc2xhc2giIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1leWUtc2xhc2ggZmEtdy0yMCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0zMjAgNDAwYy03NS44NSAwLTEzNy4yNS01OC43MS0xNDIuOS0xMzMuMTFMNzIuMiAxODUuODJjLTEzLjc5IDE3LjMtMjYuNDggMzUuNTktMzYuNzIgNTUuNTlhMzIuMzUgMzIuMzUgMCAwIDAgMCAyOS4xOUM4OS43MSAzNzYuNDEgMTk3LjA3IDQ0OCAzMjAgNDQ4YzI2LjkxIDAgNTIuODctNCA3Ny44OS0xMC40NkwzNDYgMzk3LjM5YTE0NC4xMyAxNDQuMTMgMCAwIDEtMjYgMi42MXptMzEzLjgyIDU4LjFsLTExMC41NS04NS40NGEzMzEuMjUgMzMxLjI1IDAgMCAwIDgxLjI1LTEwMi4wNyAzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5QzU1MC4yOSAxMzUuNTkgNDQyLjkzIDY0IDMyMCA2NGEzMDguMTUgMzA4LjE1IDAgMCAwLTE0Ny4zMiAzNy43TDQ1LjQ2IDMuMzdBMTYgMTYgMCAwIDAgMjMgNi4xOEwzLjM3IDMxLjQ1QTE2IDE2IDAgMCAwIDYuMTggNTMuOWw1ODguMzYgNDU0LjczYTE2IDE2IDAgMCAwIDIyLjQ2LTIuODFsMTkuNjQtMjUuMjdhMTYgMTYgMCAwIDAtMi44Mi0yMi40NXptLTE4My43Mi0xNDJsLTM5LjMtMzAuMzhBOTQuNzUgOTQuNzUgMCAwIDAgNDE2IDI1NmE5NC43NiA5NC43NiAwIDAgMC0xMjEuMzEtOTIuMjFBNDcuNjUgNDcuNjUgMCAwIDEgMzA0IDE5MmE0Ni42NCA0Ni42NCAwIDAgMS0xLjU0IDEwbC03My42MS01Ni44OUExNDIuMzEgMTQyLjMxIDAgMCAxIDMyMCAxMTJhMTQzLjkyIDE0My45MiAwIDAgMSAxNDQgMTQ0YzAgMjEuNjMtNS4yOSA0MS43OS0xMy45IDYwLjExeiI+PC9wYXRoPjwvc3ZnPg==");
                }
            }
        }
    }
}

.chevron {
    position: relative;
    width: 0.875rem;
    height: 0.875rem;
    border-top: 2px solid $secondary;
    border-right: 2px solid $secondary;
    &.chevron-right {
        transform: rotate(45deg);
    }
    &.chevron-left {
        transform: rotate(225deg);
    }
}

.text-grey {
    color: $default;
}

.text-success {
    color: $success !important;
}

.text-danger {
    color: $danger !important;
}

.check-mark {
    width: 0.5rem;
    height: 1.125rem;
    border-bottom: 0.1rem solid $success;
    border-right: 0.1rem solid $success;
    transform: rotate(45deg);
}

.dev-miles .page-title {
    text-transform: uppercase;
}

.dev-miles ul {
    padding-left: 1em;
}

.custom-list {
    list-style: none;
    padding-left: 15px;
    >li {
        position: relative;
    }
    >li::before {
        content: "";
        position: absolute;
        left: -14px;
        top: 8px;
        width: 6px;
        height: 6px;
        background-color: $black;
        border-radius: 50%;
    }
    &.list-primary {
        >li::before {
            background-color: $primary;
        }
    }
    &.list-success {
        >li::before {
            background-color: $success;
        }
    }
}

input,
textarea,
select {
    filter: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
select:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active,
select:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -moz-box-shadow: 0 0 0px 1000px white inset !important;
    background: transparent;
}

input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -moz-box-shadow: 0 0 0px 1000px white inset !important;
}

.text-primary {
    color: $color_text_default !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: $primary !important;
}

.list-group-flush .list-group-item {
    background-color: transparent;
    border-top-width: 0.001rem;
    &:hover {
        background-color: $color_smoke_gray;
    }
    &.accordion_list .float-right {
        &>a {
            color: $primary;
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 10px;
                width: 0.7rem;
                height: 0.7rem;
                border-bottom: 0.1rem solid $primary;
                border-right: 0.1rem solid $primary;
                transform: rotate(-135deg);
                cursor: pointer;
            }
            &.collapsed {
                padding-bottom: 10px;
                color: #121416;
                &::after {
                    transform: rotate(45deg);
                    top: 0px;
                    border-color: #8c8c8c;
                }
            }
        }
    }
}


/*---------------------------------------------
  Login Page Related
  ---------------------------------------------*/

.login-section {
    padding-left: 6rem;
    .disclaimer {
        color: $default;
    }
    .message {
        position: relative;
        top: 8rem;
        width: 80%;
        margin: auto;
        text-align: center;
    }
}

.login-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.otp-input {
    margin-right: 2rem;
    font-size: 2rem;
    text-align: center;
    width: 70px;
    border: none;
    border-bottom: 2px $primary solid;
    &:focus {
        outline: none;
    }
}


/*------------------------------------------------
    Dashboard Page Related
  -------------------------------------------------*/


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.navbar {
    padding: 0 0.625rem;
}

.navbar-light .navbar-brand {
    max-width: 180px;
    min-height: 50px;
    padding: 5px 0px;
    text-align: left;
    .img-c-logo {
        height: 62px;
    }
    .img-fluid {
        max-height: 70px;
        height: 70px;
        object-fit: contain;
    }
}

.notification-icon {
    position: relative;
    img {
        height: 27px;
    }
    .ni-value {
        position: absolute;
        top: 3px;
        right: 0;
        background: $primary;
        border-radius: 100px;
        color: $white;
        font-weight: 600;
        font-size: 10px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.poweredby {
    border-left: 1px solid #eee;
    padding: 0px 0px 0px 15px;
    margin: 5px 0px 5px 10px;
    color: #666;
    font-size: 12px;
    img {
        width: 90px;
        max-width: 90px;
        margin-left: 10px;
    }
}

.emr-dashboard {
    .section {
        margin-bottom: 2.5rem;
        .header {
            font-size: 1.3125rem;
            margin-bottom: 1rem;
        }
    }
}

.hospital-card {
    border-radius: 0;
    margin-bottom: 1rem;
    color: $grey;
    h4 {
        font-size: 1.1rem;
    }
    p {
        font-size: 0.875rem;
    }
    .display-2 {
        font-size: 2.1875rem;
    }
}

.syncoutline_icon {
    color: $primary;
}

.patient-card {
    .card-body {
        padding: 1rem;
    }
    a {
        text-decoration: none;
    }
}

.shadow-box {
    box-shadow: 0 0 24px 4px $shadowColor;
}

.code-alert {
    margin-bottom: 1rem;
    &.code-blue {
        @include code_alert($codeBlue);
    }
    &.code-red {
        @include code_alert($codeRed);
    }
    &.code-yellow {
        @include code_alert($codeYellow);
    }
    &.code-purple {
        @include code_alert($codePurple);
    }
    &.code-black {
        @include code_alert($codeBlack);
    }
    &.code-pink {
        @include code_alert($codePink);
    }
    .card-body {
        img {
            width: 52px;
            height: 52px;
        }
        h5 {
            text-transform: uppercase;
            font-size: 1.1rem;
        }
        p {
            color: $grey;
            margin-bottom: 0;
        }
    }
}

.code-alert-icon {
    width: 72px;
    margin-bottom: 1rem;
}

.custom-input-align {
    position: relative;
    top: -12px;
}

.text-pink {
    color: $codePink;
}


/*---------------------------------------------
  Today's Visit related
  -----------------------------------------------*/

.qms-token {
    border: 2px dashed $color_text_default;
    padding: 0.1rem 0.8rem;
    font-size: 0.85rem;
    font-weight: bold;
    margin: 0px;
    &.token-success {
        border-style: solid;
        color: $black;
        font-size: 1.125rem;
    }
}

.qms-token-card {
    border: 2px solid $color_text_default;
    border-radius: 8px;
    padding: 0.1rem 0.8rem;
    font-size: 0.85rem;
    font-weight: bold;
    margin: 0px;
    &.token-success {
        border-style: solid;
        color: $black;
        font-size: 1.125rem;
    }
}

.todays-visit-card {
    border-radius: 0;
    .card-title {
        font-size: 1.875rem;
        text-align: center;
    }
    &.card-subjective {
        @include todays_visit_card_title($subjective);
    }
    &.card-objective {
        @include todays_visit_card_title($objective);
    }
    &.card-assessment {
        @include todays_visit_card_title($assessment);
    }
    &.card-plan {
        @include todays_visit_card_title($plan);
    }
    .td-col-2 {
        td {
            width: 50%;
        }
    }
}

.wait-time {
    display: inline-block;
    font-size: 0.85rem;
    min-width: 280px;
    text-align: right;
    top: 4px;
}

.virtual_consult_icon {
    font-weight: 500;
    color: $primary;
    font-size: 0.85rem;
    position: relative;
    i {
        top: 5px;
        position: relative;
        width: 28px;
        &.icon_video {
            &::after {
                @include video_icon($fill_primary);
            }
        }
        &.icon_opd {
            height: 18px;
            top: 4px;
            &::after {
                @include opd_icon($fill_primary);
            }
        }
    }
}

.patient_cta {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 86px;
    z-index: 999;
    text-align: right;
    .btn.btn-sm {
        font-size: 0.85rem;
        padding: 0.25rem 1rem;
    }
    a {
        display: block;
    }
    .btn-rx {
        width: 92px;
        position: relative;
        top: 3px;
    }
    .on-hold-btn {
        font-size: 0.85rem;
        margin-top: 10px;
        line-height: 18px;
        color: $danger;
        cursor: default;
        .on-hold {
            display: inline-block;
            width: 18px;
            height: 18px;
            color: $white;
            padding: 0px;
            text-align: center;
            line-height: 18px;
            font-size: 12px;
            border-radius: 50%;
            background-color: $danger;
            margin-right: 5px;
            position: relative;
            top: -2px;
        }
    }
}

.time-line-container .patient_cta {
    bottom: 10px;
}


/*---------------------------------------------
 Today's Visit Today's Visit - Objective
 -----------------------------------------------*/

.cardbg {
    background: $bg_alice_blue;
    .card-header {
        background-color: transparent;
        border-bottom: none;
        &.card-flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        label {
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.75;
            margin: 0;
            color: $grey;
        }
    }
    .btn-outline-primary {
        background-color: $white;
        &:hover {
            background: $primary;
        }
    }
    .top-chart-btn {
        float: right;
        margin-left: 10px;
        outline: none;
        padding: 0;
        // padding-top: 0.25rem !important;
        // padding-bottom: 0 !important;
        &:focus {
            box-shadow: none;
        }
    }
    .vitals {
        padding: 0 0.5rem;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        overflow: hidden;
        .table {
            margin: 0px;
            overflow: hidden;
            tr {
                &.highlight_bg {
                    td {
                        &:nth-child(1) {
                            background: $white;
                            // max-width: 100px;
                            font-weight: 500;
                            box-shadow: 0 0 0 0 transparent, 0 12px 10px rgba(0, 0, 0, 0.2);
                        }
                        &:nth-child(2) {
                            background: unset !important;
                            // max-width: 100px;
                            font-weight: 400;
                            box-shadow: unset !important;
                        }
                    }
                }
            }
            tr {
                &:first-child {
                    td,
                    th {
                        padding-top: 20px;
                        vertical-align: text-top;
                        font-weight: 500;
                        color: #222;
                    }
                    td:nth-child(1),
                    td:nth-last-child(2),
                    th:nth-child(1),
                    th:nth-last-child(2) {
                        font-size: 1.025rem;
                        color: #333;
                    }
                }
                td {
                    border: none;
                    padding: 0.4rem 0.75rem;
                    vertical-align: text-top;
                    button.chart-btn {
                        padding: 0.25rem;
                    }
                    &:last-child {
                        max-width: 60px;
                        width: 60px;
                        text-align: center;
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    &:nth-child(2) {
                        background: $white;
                        // max-width: 100px;
                        font-weight: 500;
                        box-shadow: 0 0 0 0 transparent, 0 12px 10px rgba(0, 0, 0, 0.2);
                    }
                    .vital-time {
                        font-size: 13px;
                    }
                    .vital-source {
                        font-size: 12px;
                        color: #6f6d6d;
                    }
                }
            }
        }
    }
    .text-danger {
        color: $codeRed !important;
    }
    &.table-full {
        // .card-header {
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-start;
        // }
        // .vital-right {
        // .vr-badge {
        //   display: flex;
        //   align-items: center;
        //   span.ant-badge-status-dot {
        //     height: 16px;
        //     width: 16px;
        //     border: 1px solid #afafaf;
        //     border-radius: 0;
        //   }
        // }
        // }
        .vitals {
            .table {
                tr {
                    td {
                        &:nth-last-child(2),
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.vital-time {
    font-size: 15px;
}

.vital-source {
    font-size: 14px;
    color: #6f6d6d;
}

.vr-badge {
    display: flex;
    align-items: center;
    span.ant-badge-status-dot {
        height: 16px;
        width: 16px;
        border: 1px solid #d24350;
        border-radius: 0;
    }
    span.ant-badge-status-text {
        font-size: 15px;
    }
}

.legends-badge {
    display: inline;
    align-items: center;
    span.ant-badge-status-dot {
        height: 16px;
        width: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 0;
    }
    span.ant-badge-status-text {
        font-size: 15px;
    }
}

.severity_list {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    list-style: none;
    li {
        display: inline-block;
        margin-right: 1.25rem;
        i {
            float: left;
            margin-right: 0.35rem;
        }
    }
}

.down-arrow:after {
    display: inline-block;
    content: "";
    border-top: 0.45em solid;
    border-right: 0.45em solid transparent;
    border-bottom: 0;
    border-left: 0.45em solid transparent;
    border-top-color: #333;
    border-bottom-color: #333;
}

.current-time {
    background: #ededed;
    color: $primary;
    display: inline-block;
    padding: 4px 10px;
}

.allergies .card {
    h5 {
        font-weight: 400;
    }
    ul {
        margin: 0px;
        padding: 0;
        list-style: none;
        &+ul {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            margin-top: 10px;
            padding-top: 10px;
        }
    }
}

.add-allergies {
    .nav-tabs {
        border: none;
        .nav-item {
            padding: 0 1rem 1rem;
            .nav-link {
                border: none;
                color: #333;
                padding: 6px 10px;
                &.active {
                    color: $primary;
                    border-bottom: solid 0.095rem $primary;
                }
            }
        }
    }
    .tab-content {
        padding: 10px;
    }
}

canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.wrap_chart_filter {
    position: absolute;
    // left: -90px;
    top: 15px;
    padding-left: 32%;
    &_small {
        padding-left: 0;
    }
}

ul.chart_filter {
    list-style: none;
    display: table;
    border: solid 2px $primary;
    padding: 0px;
    margin: 0;
    border-radius: 8px;
    background: $white;
    li {
        display: table-cell;
        border-right: solid 2px $primary;
        &:last-child {
            border: none;
        }
        &.datepicker {
            .icon-calendar {
                width: 16px;
                height: 16px;
            }
            .btn:hover {
                background-color: $white;
                border-top-color: $primary;
                border-right-color: $primary;
            }
        }
        .btn {
            width: 101%;
            border-radius: unset;
            box-shadow: none !important;
            padding: 0.15rem 1rem !important;
            &.active {
                background: $primary;
                color: $white;
            }
            &:hover {
                background: $primary;
                color: $white;
            }
        }
        &:first-child .btn {
            border-radius: 6px 0 0 6px;
        }
        &:last-child .btn {
            border-radius: 0 6px 6px 0;
        }
        &.custome-datepicker .btn {
            background-color: $white;
            i.icon-calendar {
                width: 19px;
                height: 19px;
                position: relative;
                top: 2px;
            }
        }
    }
}

.chart_width_iner {
    background: #f3f3f3;
    width: 100%;
    padding: 10px 10px 20px 0;
    position: relative;
    //margin-left: 11px;
    h4 {
        margin: 0;
        padding: 5px 15px 5px;
        font-size: 16px;
    }
    &.align {
        margin-top: 0px !important;
        margin-bottom: 10px;
        h4 {
            margin-top: 30px;
        }
    }
    .check_for_print {
        position: absolute;
        right: 0px;
        top: 16px;
        .custom-checkbox .custom-control-input {
            left: -2px;
            top: 2px;
            width: 24px;
            height: 24px;
            z-index: 99;
        }
        .custom-checkbox .custom-control-label:before {
            background-color: $white !important;
            width: 18px;
            height: 18px;
            border-color: $primary;
        }
        .custom-checkbox .custom-control-label:after {
            top: 0.1rem !important;
            left: -1.55rem !important;
        }
    }
}

.chart_width_iner_single {
    background: #f3f3f3;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-left: 11px;
    h4 {
        margin: 0;
        padding: 5px 15px 5px;
        font-size: 16px;
    }
    &.align {
        margin-top: 0px !important;
        margin-bottom: 10px;
        h4 {
            margin-top: 30px;
        }
    }
    .check_for_print {
        position: absolute;
        right: 0px;
        top: 6px;
        .custom-checkbox .custom-control-input {
            left: -2px;
            top: 2px;
            width: 24px;
            height: 24px;
            z-index: 99;
        }
        .custom-checkbox .custom-control-label:before {
            background-color: $white !important;
            width: 18px;
            height: 18px;
            border-color: $primary;
        }
        .custom-checkbox .custom-control-label:after {
            top: 0.1rem !important;
            left: -1.55rem !important;
        }
    }
}

.chartCanvasWrapper {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
    .chartCanvas {
        background: #f3f3f3;
        padding: 10px 10px 15px 0px;
        position: relative;
        flex: 1;
        max-width: calc((100% / 3) - 20px);
        min-width: calc((100% / 3) - 20px);
        margin: 10px;
        h4 {
            margin: 0;
            padding: 5px 15px 5px;
            font-size: 16px;
        }
        canvas {
            background: transparent !important;
            margin-left: -10px;
            padding-left: 24px;
            padding-bottom: 17px;
        }
        .check_for_print {
            position: absolute;
            right: 0px;
            top: 6px;
            .custom-checkbox .custom-control-input {
                left: -2px;
                top: 2px;
                width: 24px;
                height: 24px;
                z-index: 99;
            }
            .custom-checkbox .custom-control-label:before {
                background-color: $white !important;
                width: 18px;
                height: 18px;
                border-color: $primary;
            }
            .custom-checkbox .custom-control-label:after {
                top: 0.1rem !important;
                left: -1.55rem !important;
            }
        }
        .date-select {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 180px;
            .form-control {
                height: 32px;
            }
            .input-group-addon {
                min-width: 48px;
                padding: 1px;
            }
             ::-webkit-input-placeholder {
                color: transparent;
            }
             :-ms-input-placeholder {
                color: transparent;
            }
             ::-ms-input-placeholder {
                color: transparent;
            }
             ::-moz-placeholder {
                color: transparent;
            }
             ::placeholder {
                color: transparent;
            }
        }
        .currentData {
            display: inline-block;
            padding: 8px 32px;
            background: $codeRed;
            color: $white;
            border-radius: 8px;
            font-size: 16px;
            line-height: 1;
        }
        canvas {
            background: #f3f3f3;
        }
        &.singledata {
            canvas {
                margin-left: 0px;
                padding-left: 10px;
            }
        }
    }
    .chartCanvasNew {
        .check_for_print {
            position: absolute;
            right: 0px;
            top: 6px;
            .custom-checkbox .custom-control-input {
                left: -2px;
                top: 2px;
                width: 24px;
                height: 24px;
                z-index: 99;
            }
            .custom-checkbox .custom-control-label:before {
                background-color: $white !important;
                width: 18px;
                height: 18px;
                border-color: $primary;
            }
            .custom-checkbox .custom-control-label:after {
                top: 0.1rem !important;
                left: -1.55rem !important;
            }
        }
    }
    &.chartCanvasWrapperSmall {
        .chartCanvas {
            flex: 1;
            max-width: 100%;
            min-width: 560px;
            margin: 0;
        }
    }
    &.chartCanvasWrapperSmallRight {
        .chartCanvas {
            flex: 1;
            max-width: 100%;
            min-width: 290px;
            margin: 0;
        }
    }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: 0;
}

.img-circle {
    border-radius: 50%;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-heading {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
}

.card-list {
    .card-header {
        background: $white;
        color: $primary;
        font-size: 18px;
    }
    .card-body {
        padding: 0;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                padding: 0.75rem 1.25rem;
                margin: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                position: relative;
                cursor: pointer;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 15px;
                    top: 20px;
                    font-size: 32px;
                    width: 0.65rem;
                    height: 0.65rem;
                    border-top: 0.075rem solid $color_text_default;
                    border-right: 0.075rem solid $color_text_default;
                    transform: rotate(45deg);
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.accordion_item_row {
    padding: 0.75rem 0 0;
    .accordion_item_head {
        font-weight: 500;
        font-size: 90%;
        position: relative;
        margin-bottom: 0.75rem;
        cursor: pointer;
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0px;
            top: 8px;
            width: 0.7rem;
            height: 0.7rem;
            border-bottom: 0.1rem solid $color_table_header;
            border-right: 0.1rem solid $color_table_header;
            transform: rotate(-135deg);
            cursor: pointer;
        }
        &.collapsed {
            &:after {
                transform: rotate(45deg);
                top: 2px;
            }
        }
    }
    .accordion_item_body {
        font-size: 90%;
        &.collapsing {
            -webkit-transition: height 0.03s;
            -moz-transition: height 0.03s;
            -ms-transition: height 0.03s;
            -o-transition: height 0.03s;
            transition: height 0.03s;
        }
        table td,
        table th {
            padding: 0.15rem 0rem;
        }
        .taper_list {
            margin: 0.5rem -1.27rem 0 -1.25rem;
            padding: 1rem 0.25rem 0;
            background: $bg-grey;
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            ul {
                margin: 0px;
                padding: 0 0 0 2rem;
                list-style: none;
                position: relative;
                &:before {
                    border-left: solid 0.125rem #ccc;
                    content: "";
                    position: absolute;
                    left: 1rem;
                    height: 100%;
                }
                li {
                    padding: 0.25rem 0;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        left: -1.325rem;
                        top: 0;
                        width: 0.8rem;
                        height: 0.8rem;
                        border-radius: 50%;
                        background: $primary;
                    }
                    h5 {
                        font-size: 0.85rem;
                        margin: 0;
                        text-transform: uppercase;
                        color: $primary;
                        font-weight: 500;
                        position: relative;
                        top: -0.35rem;
                        left: 0rem;
                    }
                }
            }
        }
        table tr th {
            font-weight: 500;
            color: $color_table_header;
        }
    }
    &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
}

#medicine-details .accordion_item_row {
    padding: 0.75rem 1.2rem 0;
}

.search-card {
    padding: 1.125rem;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0;
    border-color: rgba(0, 0, 0, 0.05);
    &:hover {
        background-color: rgba(0, 0, 0, 0.01);
    }
    .text-heading {
        margin-bottom: 0.25rem;
    }
    .icon_phone {
        width: 14px;
        &:after {
            transform: rotate(20deg);
            background-position-y: bottom;
        }
    }
    .font-14 {
        font-size: 14px;
    }
}

.check-primary {
    .custom-checkbox .custom-control-label:before {
        background-color: $white !important;
        width: 18px;
        height: 18px;
        border-color: $primary !important;
    }
    .custom-checkbox .custom-control-label:after {
        top: 0.1rem !important;
        left: -1.55rem !important;
    }
}

.table tbody tr td.action {
    position: relative;
    a.hidden {
        visibility: hidden;
    }
    &:after {
        background: #869eb6;
    }
}

.table tbody tr td.action {
    position: relative;
    a.hidden {
        visibility: hidden;
    }
    a.edit-link {
        width: 22px;
        height: 22px;
        background: url(../images/ic-edit.svg) no-repeat center center;
        display: inline-block;
        background-size: cover;
    }
}

.mycalendar-tabs {
    list-style: none;
    border: solid 2px $primary;
    padding: 0;
    margin: 0 auto;
    border-radius: 8px;
    background: $white;
    display: -webkit-flex;
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
    li {
        display: table-cell;
        border-right: solid 2px $primary;
        -webkit-flex: 1;
        flex: 1;
        text-align: center;
        .nav-link.active {
            background-color: $primary;
            border-radius: 0;
            margin: 0 -1px;
        }
        &:first-child .nav-link.active {
            border-radius: 0.25rem 0 0 0.25rem;
        }
        &:last-child {
            border-right: none;
            .nav-link {
                border-radius: 0 0.25rem 0.25rem 0;
            }
        }
    }
}


/*------------------------------------------------
   bootstrap tagsinput
 -------------------------------------------------*/

.bootstrap-tagsinput {
    background-color: $white;
    border: solid 1px #ced4da;
    border-radius: 0.25rem;
    min-height: calc(1.8em + 0.75rem + 2px);
    padding: 0.425rem 0.75rem 0.375rem;
    outline: 0 !important;
    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: auto;
        max-width: inherit;
    }
    .tag {
        margin: 3px 2px 2px 0;
        color: #495057;
        font-size: 0.9rem;
        line-height: 1;
        padding: 3px 4px 4px;
        background: #f0f0f0;
        display: inline-block;
        border: solid 1px #ddd;
        [data-role="remove"] {
            margin-left: 5px;
            cursor: pointer;
            color: #aaa;
            font-size: 1.1rem;
            &:after {
                content: "x";
                padding: 0px 2px;
            }
        }
        &:hover {
            background-color: #e6e9ff;
            border-color: #c1c7f3;
            [data-role="remove"] {
                color: #ffadad;
                &:hover {
                    color: #f00;
                }
            }
        }
    }
}


/*---------------------------------------------
 EMR Form Style
 -----------------------------------------------*/

.emrfrm {
    .form-control {
        height: calc(1.75em + 0.75rem + 2px);
        position: relative;
    }
    textarea.form-control {
        min-height: 44px;
        padding-top: 0.575rem;
        overflow: hidden;
    }
     ::-webkit-input-placeholder {
        color: transparent;
    }
     :-ms-input-placeholder {
        color: transparent;
    }
     ::-ms-input-placeholder {
        color: transparent;
    }
     ::-moz-placeholder {
        color: transparent;
    }
     ::placeholder {
        color: transparent;
    }
    .form-label-group>label,
    .form-group>label {
        transition: all 0.1s ease-in-out;
        top: 10px;
        left: 10px;
        position: absolute;
        display: block;
        outline: none;
        z-index: 99;
        pointer-events: none;
        &.required span {
            color: $danger;
        }
    }
    .form-label-group,
    .form-group {
        position: relative;
    }
    .form-label-group .form-control {
        border-right: none;
    }
    .form-group.adv-search {
        position: relative;
        .form-control {
            padding-right: 80px;
        }
        .btn {
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
            padding: 0.15rem 1rem;
        }
        .add_instruction {
            display: none;
        }
        &.notfound {
            .find_instruction {
                display: none;
            }
            .add_instruction {
                display: block;
            }
        }
    }
    .multi-input-col label {
        top: -10px;
        display: inline-block;
        background: $white;
        padding: 0 3px;
        left: 10px;
    }
    .select2-container {
        width: 100% !important;
        flex: 1 1 auto;
        max-width: 100% !important;
        outline: none !important;
    }
    .input-group .select2-container {
        width: 1% !important;
    }
    .input-group .select2-container--default .select2-selection--single {
        border-right: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .select2-container--default .select2-selection--single {
        border: 1px solid #ced4da !important;
        height: 44px !important;
        border-radius: 0.25rem;
        outline: none;
        &:focus {
            border: solid 1px $primary !important;
        }
        .select2-selection__arrow {
            top: 8px !important;
            right: 5px !important;
            b {
                border: none !important;
                left: 0px;
                top: 4px;
                margin: 0px;
                background: url(../images/ic-arrow-down.svg) left/18px 14px no-repeat !important;
                width: 20px;
                height: 20px;
            }
        }
        .select2-selection__rendered {
            line-height: 44px;
            padding-left: 12px !important;
        }
    }
    .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: #f9f9f9;
    }
    .input-group-addon {
        padding: 5px;
        font-size: 1rem;
        line-height: 1.75;
        font-weight: 400;
        text-align: center;
        background-color: $lightgrey;
        border: 1px solid #ccc;
        min-width: 75px;
        select {
            background-color: $lightgrey;
            border: none;
            outline: none;
            padding: 0rem 1.25rem 0 0.5rem;
            height: auto;
            outline: none;
            box-shadow: none;
            background: url(../images/ic-arrow-down.svg) right/18px 14px no-repeat !important;
        }
        &.max-120 {
            min-width: 120px;
            select {
                max-width: 120px;
            }
        }
    }
    .form-control~.input-group-addon {
        border-radius: 0 4px 4px 0;
        &.bg-white {
            border-left: none;
            min-width: 45px;
            img,
            i {
                position: relative;
                top: 0px;
                &.icon-calendar {
                    top: 5px;
                }
            }
        }
    }
    .form-control:disabled~.input-group-addon,
    .form-control[readonly]~.input-group-addon {
        &.bg-white {
            border-left: 1px solid #ced4da;
            background-color: #eff0f0 !important;
        }
    }
    .input-group>.form-control:focus {
        border-color: #ced4da;
        box-shadow: none;
    }
    .form-control:focus~.input-group-addon {
        border-color: $primary;
        border-left-color: #ced4da;
    }
    .input-group:focus-within {
        border-color: $color_text_default;
        box-shadow: none !important;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-control.focus>label {
        font-size: 13px;
        color: $primary;
        top: -10px;
        display: inline-block;
        background: $white;
        padding: 0 3px;
        left: 25px !important;
    }
    .multi-input-col {
        .form-control {
            position: relative;
            input {
                width: auto;
                max-width: 48px;
                border: none;
                outline: none;
                position: relative;
                z-index: 2;
                background: transparent;
            }
            label {
                position: absolute;
                top: 9px !important;
            }
        }
        &.hasdata {
            label {
                color: $primary;
                font-size: 13px;
                position: absolute;
                top: -11px !important;
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: -webkit-gradient( left top, left bottom, color-stop(50%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
            }
            &:after {
                content: "/";
                display: inline-block;
                position: absolute;
                left: 48px;
                top: 2px;
                color: #999;
                font-size: 22px;
                z-index: 99999;
            }
            &:focus {
                border: 1px solid $primary;
            }
        }
    }
    .input-group-addon.bg-primary {
        background-color: $primary !important;
        border-color: $primary !important;
        max-width: 50px;
        .custom-select {
            background-color: $primary;
        }
    }
    .input-group-addon.btn-icon {
        padding: 0;
        border: none;
        .btn {
            border-radius: 0 4px 4px 0;
            width: 100%;
            height: 100%;
            box-shadow: none !important;
            &.down-arrow {
                &:after {
                    border-top-color: $white;
                    border-bottom-color: $white;
                }
            }
        }
    }
    .input-group-addon.img {
        padding: 2px;
        vertical-align: middle;
    }
    .input-group-addon.bp-field {
        background: $white;
        min-width: 128px;
        padding-right: 10px;
        padding-top: 10px;
        padding-left: 10px;
        text-align: left;
    }
    .input-group-addon.bp-field i {
        display: none;
        position: absolute;
        right: 20px;
        width: 0.5rem;
        height: 1.125rem;
        border-bottom: 2px solid $primary;
        border-right: 2px solid $primary;
        transform: rotate(45deg);
    }
    .bootstrap-select>.dropdown-toggle {
        background-color: $white;
        border-color: #ced4da;
        border-radius: 0.25rem;
        height: calc(1.8em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        outline: none !important;
        &:before {
            content: "";
            display: block;
            position: absolute;
            background: #126dcb;
            width: 75px;
            height: calc(1.8em + 0.75rem + 2px);
            top: -1px;
            right: -1px;
            z-index: 1;
            border-radius: 0 0.25rem 0.25rem 0;
        }
        &:after {
            z-index: 2;
            border-top: 0.45em solid $white;
            border-right: 0.45em solid transparent;
            border-bottom: 0;
            border-left: 0.45em solid transparent;
            position: relative;
            left: -14px;
        }
        &:hover &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important;
            border-color: $color_text_default;
        }
    }
    .btn {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        font-weight: 500;
        font-size: 0.875rem;
        &:disabled {
            cursor: not-allowed;
        }
    }
    .btn-light.focus,
    .btn-light:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus,
    .btn-light:not(:disabled):not(.disabled):active:focus,
    .show>.btn-light.dropdown-toggle:focus {
        box-shadow: none !important;
    }
    .field-error {
        color: $danger;
        float: left;
        width: 100%;
        font-size: 80%;
        padding-top: 2px;
    }
}

.required small {
    color: $danger;
}

.required-fields {
    border-color: $danger !important;
}

.required-fields+.input-group-addon {
    border-color: $danger !important;
}

.emrfrm .form-label-group.hasdata>label,
.emrfrm .form-group.hasdata>label {
    font-size: 13px;
    color: $primary;
    top: -11px;
    display: inline-block;
    padding: 0 3px;
    left: 10px;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient( left top, left bottom, color-stop(50%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.emrfrm .card.bg-grey .form-label-group.hasdata>label,
.emrfrm .card.bg-grey .form-group.hasdata>label {
    background: -moz-linear-gradient(top, rgba(239, 239, 239, 0) 50%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
    background: -webkit-gradient( left top, left bottom, color-stop(50%, rgba(239, 239, 239, 1)), color-stop(50%, rgba(239, 239, 239, 1)), color-stop(100%, rgba(239, 239, 239, 1)));
    background: -webkit-linear-gradient(top, rgba(239, 239, 239, 0) 50%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
    background: -o-linear-gradient(top, rgba(239, 239, 239, 0) 50%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
    background: -ms-linear-gradient(top, rgba(239, 239, 239, 0) 50%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
    background: linear-gradient(to bottom, rgba(239, 239, 239, 0) 50%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
}

.emrfrm .card.bg-grey .form-group.hasdata>.bg-white~label,
.emrfrm .card.bg-grey .form-group.hasdata.bg-white>label {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient( left top, left bottom, color-stop(50%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.emrfrm .form-group.hasdata .form-control:disabled~label,
.emrfrm .form-group.hasdata .form-control[readonly]~label {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 1) 100%);
}

.emrfrm .form-group.hasdata>.form-control.bg-grey~label,
.emrfrm .form-group.hasdata>.bg-grey~label {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(244, 244, 244, 1) 50%, rgba(244, 244, 244, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(244, 244, 244, 1) 50%, rgba(244, 244, 244, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(244, 244, 244, 1) 50%, rgba(244, 244, 244, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(244, 244, 244, 1) 50%, rgba(244, 244, 244, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(244, 244, 244, 1) 50%, rgba(244, 244, 244, 1) 100%);
}

.emrfrm .custom-control-input:checked~.custom-control-label::before {
    border-color: $primary;
}

.emrfrm .custom-control.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-color: $primary;
}

.emrfrm .custom-control.custom-radio .custom-control-input~.custom-control-label::before {
    background-color: $white;
}

.custom-checkbox-favourite {
    top: 0px;
    border-right: solid 1px $primary;
    padding-right: 15px;
    .custom-control-label:before {
        @include star_icon("fb8805");
        background-color: transparent !important;
        border: none;
    }
    .custom-control-label:after {
        display: none;
    }
    .custom-control-input:checked~.custom-control-label:before {
        @include fillstar_icon("fb8805");
        background-color: $white;
    }
    &.only-favourite {
        top: 2px;
        border-right: none;
        padding-right: 10px;
    }
    &.nt {
        top: 0px;
    }
}

.custom-checkbox-selectall {
    padding-right: 2.3rem;
    label:first-child {
        position: relative;
        top: 0.35rem;
        font-size: 1.1rem;
    }
    .custom-control-label {
        &:before {
            left: unset;
            right: -2rem;
            top: 0.35rem;
            width: 1.25rem;
            height: 1.25rem;
            border-width: 0.12rem;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.3) !important;
            border-radius: 50% !important;
            background: none !important;
        }
        &:after {
            left: unset;
            right: -2rem;
            position: absolute;
            left: 1.25rem;
            top: 0.6rem;
            width: 0.32rem;
            height: 0.65rem;
            border-bottom: 0.125rem solid rgba(0, 0, 0, 0.3);
            border-right: 0.125rem solid rgba(0, 0, 0, 0.3);
            transform: rotate(45deg);
        }
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: $success !important;
    }
    .custom-control-input:checked~.custom-control-label::after {
        border-color: $success;
    }
}

.admission_advised_toggle {
    display: none;
    .toggle {
        min-width: 130px !important;
    }
}

.icon_delay {
    width: 24px;
    height: 24px;
    position: relative;
    top: 6px;
    i {
        width: 24px;
        height: 24px;
        &:before {
            content: "";
            display: block;
            @include clock_icon($fill_primary);
        }
    }
}


/*---------------------------------------------
  Today's Visit - Assessment related
  ---------------------------------------------*/

.image-references {
    display: inline-block;
    margin-right: 0.75rem;
    max-width: 250px;
    img {
        width: 350px;
    }
}

// #therapy_text {
//   display: none;
// }
#pills-freehand {
    .freehand-toolbar {
        position: absolute;
        top: 0;
        right: 0;
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            background-color: #d9d9d9;
            li {
                padding: 0.35rem 0.65rem;
                text-align: center;
            }
        }
    }
}

#pills-library {
    a.selected::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 18px;
        width: 14px;
        height: 28px;
        border-bottom: 3px solid #126dcb;
        border-right: 3px solid #126dcb;
        transform: rotate(45deg);
    }
}

#image-annotate-sketchpad {
    background-size: 400px;
    background-repeat: no-repeat;
    background-position: center center;
}

.btn-cancel,
.btn-true {
    border: 2px solid rgba($black, 0.3) !important;
    width: 34px;
    height: 34px;
    border-radius: 50% !important;
    display: inline-block;
    margin: 0 5px;
    position: relative;
    bottom: 5px;
    text-align: center;
    line-height: 34px;
    i {
        font-style: normal;
    }
}

.btn-cancel i {
    &:before,
    &:after {
        content: "";
        width: 14px;
        height: 0.12rem;
        background: rgba($black, 0.3);
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 14px;
        left: 8px;
    }
    &:after {
        transform: rotate(135deg);
    }
}

.btn-true {
    i {
        position: absolute;
        left: 11px;
        top: 6px;
        width: 0.5rem;
        height: 1rem;
        border-bottom: 2px solid rgba($black, 0.3);
        border-right: 2px solid rgba($black, 0.3);
        transform: rotate(45deg);
    }
    &.accept {
        border-color: $success !important;
        i {
            border-bottom-color: $success;
            border-right-color: $success;
        }
    }
}

.btn-true-sm {
    border-width: 0.12rem;
    border-style: solid;
    border-color: rgba($black, 0.3);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 2px;
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0.4rem;
        top: 0.12rem;
        width: 0.3rem;
        height: 0.65rem;
        border-bottom: 0.12rem solid rgba(0, 0, 0, 0.3);
        border-right: 0.12rem solid rgba(0, 0, 0, 0.3);
        transform: rotate(45deg);
    }
    &.accept {
        border-color: $success;
        &:after {
            border-color: $success;
        }
    }
}

.assessment_list {
    .boxv {
        color: rgba($black, 0.3);
    }
    .border-bottom {
        border-bottom: 1px solid rgba($black, 0.2) !important;
    }
}

.table tbody tr td.image-reference-column .image-references .image-item {
    width: 250px;
}


/*--------------------------------------------------
    Todays Visit - Lab test related
  ---------------------------------------------------*/

.card {
    &.bg-grey {
        background-color: $bg-grey;
        border: 1px solid #d3d1d1;
        .form-section-title {
            font-size: 0.875rem;
            color: $grey;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }
    }
}

.card.bg-warning {
    background-color: $bg-warning-bg !important;
    border-color: $bg-warning-border;
}

.myhealthcare-tabs {
    margin-bottom: 1.5rem;
    .nav-item {
        width: 20%;
        .nav-link {
            width: 100%;
            display: block;
            text-align: center;
            border-radius: 0;
            border: 1px solid $color_gray_border;
            border-left: 0;
            padding: 0.3rem 0.5rem;
            color: $grey;
            background-color: $lightgrey;
            &.active {
                background-color: $color_white;
                color: $primary;
            }
        }
        &.show>.nav-link {
            background-color: $lightgrey;
        }
        &.nav-width {
            width: 28% !important
        }
        &.nav-width-pen {
            width: 15% !important;
        }
        &.nav-width-com {
            width: 22% !important;
        }
    }
    &.auto-width {
        .nav-item {
            width: auto;
            .nav-link {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }
    .nav-item:first-child {
        .nav-link {
            border: 1px solid $color_gray_border;
            border-radius: 6px 0 0 6px;
        }
    }
    .nav-item:last-child {
        .nav-link {
            border: 1px solid $color_gray_border;
            border-left: 0;
            border-radius: 0 6px 6px 0;
        }
    }
    &.nurse {
        .nav-item {
            width: 35%;
        }
    }
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: $grey;
    caption-side: top !important;
}

.copy-btn {
    position: absolute;
    right: -90px;
    top: -7px;
}

.bg-light {
    .list-group {
        .list-group-item {
            position: relative;
            padding-right: 65px;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            text-transform: capitalize;
            p {
                margin-bottom: 0;
            }
            a.edit-link {
                width: 20px;
                height: 20px;
                background: url(../images/ic-edit.svg) no-repeat center center;
                display: block;
                position: absolute;
                right: 32px;
                top: 10px;
                background-size: cover;
                fill: #ff00ff;
            }
            a.delete-relation-right {
                width: 16px;
                height: 18px;
                background: url(../images/ic-delete.svg) no-repeat center center;
                display: block;
                position: absolute;
                right: 6px;
                top: 12px;
                background-size: contain;
            }
        }
    }
}

.badge {
    font-size: 1rem;
    font-weight: normal;
    border-radius: 14px;
    padding: 0.35rem 0.65rem;
    &.badge-danger {
        background-color: $danger-dark;
    }
}


/*------------------------------------------------
  Key Finding
 ---------------------------------------------------*/

.keyfind_nav {
    .nav-item {
        background: $color_smoke_gray;
        .nav-link {
            text-transform: uppercase;
            color: #333;
            padding: 0.15rem 1.78rem 0.2rem;
            border: solid 1px $color_gray_border;
            border-radius: 0;
            &.active {
                background: $primary;
                color: $color_white;
                border-radius: 0;
                border: solid 1px $primary;
            }
            &.gohome {
                line-height: 1;
                padding: 0.02rem 0.75rem;
                i.icon-home {
                    width: 28px;
                    height: 27px;
                    &:before {
                        @include home_icon($fill_primary);
                    }
                }
                &:hover {
                    background-color: $primary;
                    i.icon-home:before {
                        @include home_icon($fill_white);
                    }
                }
            }
        }
        &.goback {
            position: absolute;
            right: 10px;
            .btn {
                font-weight: 500;
            }
        }
    }
}

.keyfind_sub_nav {
    margin-bottom: 1rem;
    &>.active {
        display: flex;
    }
    a {
        padding: 0.2rem 2.25rem 0.25rem;
        color: #000;
        border: solid 1px $color_table_header;
        text-decoration: none;
        &:hover,
        &.active {
            color: $primary;
        }
    }
}

.keyfinding_block {
    padding: 0.5rem 1.5rem 1.5rem;
    .myhealthcare-tabs {
        width: 72%;
        margin-left: auto;
        margin-right: auto;
    }
    .border {
        border: solid 2px #999999 !important;
        padding: 1rem;
    }
    hr {
        border-top: 2px solid rgba(0, 0, 0, 0.4);
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .table {
        border-left: solid 1px rgba(0, 0, 0, 0.1);
        border-right: solid 1px rgba(0, 0, 0, 0.1);
        thead {
            background: $color_smoke_gray;
            th,
            td {
                color: $color_text_default;
            }
        }
        th,
        td {
            padding: 0.4rem 0.75rem;
        }
        &.nob {
            th,
            td {
                border-left: none;
                border-right: none;
            }
        }
    }
    .emrfrm .btn {
        padding: 0 0.5rem;
    }
    .finding_heading {
        background: $color_table_header;
        color: $color_white;
        padding: 0.75rem 0.9rem;
        width: 100%;
    }
    .test-title {
        font-size: 0.9rem;
        color: $black;
        padding: 0.5rem 0rem;
        position: relative;
        .test_delete {
            position: relative;
            top: -5px;
        }
    }
    td.sn {
        width: 50px;
        vertical-align: top;
        position: relative;
        strong {
            position: relative;
            top: 0.35rem;
        }
    }
}

.test_panel {
    max-height: 600px;
    overflow-y: auto;
    padding: 0 15px 0 5px;
}


/*-------------------------------------------------
   Add Appointment Calendar changes
 -------------------------------------------------*/

.calendar-card {
    .table {
        margin-bottom: 0;
        thead {
            background-color: $color_table_header;
            color: $white;
            font-size: 1.1rem;
            font-weight: normal;
            text-transform: capitalize;
            text-align: center;
            tr {
                td {
                    &.control.disabled {
                        cursor: not-allowed;
                    }
                    &.control:not(.disabled) {
                        cursor: pointer;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 1.1rem;
                    color: $grey;
                    font-weight: bold;
                    text-align: center;
                    padding: 1rem 0.35rem 1rem 0.35rem;
                    &.pickadate-disabled {
                        cursor: not-allowed;
                        color: rgba(51, 51, 51, 0.5);
                    }
                    &.pickadate-enabled {
                        cursor: pointer;
                        &:hover {
                            color: $primary;
                        }
                    }
                    &.selected {
                        >span {
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            padding: 5px;
                            background-color: $primary;
                            color: $white;
                            border-radius: 50%;
                        }
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.slots {
    .list-inline-item {
        width: calc((100% / 4) - 0.7rem);
        margin: 0 1rem 1rem 0;
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
        .slot {
            width: 100%;
            border-radius: 3px;
            border-color: $slotAvailable;
            color: $slotAvailable;
            &.notbooked {
                border-color: $slotLapsed !important;
                color: $slotLapsed !important;
                cursor: not-allowed;
                &:focus {
                    box-shadow: none;
                }
                &.booked {
                    color: $slotBooked !important;
                    border-color: $slotBooked !important;
                    &:before {
                        border-bottom-color: $slotBooked;
                        border-right-color: $slotBooked;
                    }
                    &:hover {
                        background: $slotBooked !important;
                        border-color: $slotBooked !important;
                        color: $color_white !important;
                        &:before {
                            border-bottom-color: $color_white;
                            border-right-color: $color_white;
                        }
                    }
                }
            }
            &.active {
                &.booked {
                    background: $slotBooked !important;
                    border-color: $slotBooked !important;
                    color: $color_white !important;
                    cursor: not-allowed;
                }
            }
        }
        .slot:not(.notbooked) {
            &:hover {
                background: $slotAvailable !important;
                color: $white !important;
            }
        }
    }
}

.error_alert span.txtalert {
    color: #ff2626;
    font-size: 14px;
}


/*--------------------------------------------------
    Multi select Drop Down
  ---------------------------------------------------*/

.bootstrap-select>.dropdown-toggle {
    height: calc(1.8rem + 0.75rem + 2px);
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    background-color: $white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background: $white !important;
    outline: none !important;
    &:focus,
    &:active {
        outline: none !important;
    }
    &.disabled:after {
        display: none;
    }
}

.bootstrap-select .dropdown-menu li a span.check-mark {
    border-bottom: none;
    border-right: none;
    transform: rotate(0deg);
}

.bootstrap-select.show-tick .dropdown-menu span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 5px;
    &:after {
        display: none;
    }
    &:before {
        content: "";
        border: solid 1px #b0bdcc;
        display: block;
        width: 24px;
        height: 22px;
        position: absolute;
        right: -7px;
        top: -1px;
        z-index: 1;
    }
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark:after {
    display: block;
    z-index: 2;
}

#medicine-list,
#test-list {
    display: none;
}

.custom-checkbox {
    .custom-control-label {
        &:before {
            background: $white !important;
            border-radius: 0 !important;
        }
    }
    .custom-control-input {
        left: 2px;
        top: 5px;
        &:checked~.custom-control-label::after {
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d24350' viewBox='0 0 520 500'%3E%3Cpath d='m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z'/%3E%3C/svg%3E") !important;
            top: 0.1rem;
            left: -1.6rem;
        }
        &:disabled~.custom-control-label {
            cursor: not-allowed;
            &::before,
            &::after {
                opacity: 0.65;
            }
        }
    }
}

.custom-radio {
    .custom-control-input {
        &:disabled~.custom-control-label {
            cursor: not-allowed;
            &::before,
            &::after {
                opacity: 0.65;
            }
        }
    }
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control.custom-checkbox-md {
    .custom-control-input {
        left: 5px;
        top: 14px;
        z-index: 3;
    }
    .custom-control-label {
        left: 0.5rem;
        top: 0.5rem;
        &:before {
            width: 1.5rem;
            height: 1.5rem;
            left: -2rem;
            top: 0;
            border-radius: 0.2rem;
        }
        &:after {
            position: absolute;
            right: 20px;
            width: 0.5rem;
            height: 1rem;
            top: 0.15rem;
            border-bottom: 0.2rem solid $white;
            border-right: 0.2rem solid $white;
            transform: rotate(45deg);
            display: none;
        }
    }
    .custom-control-input:checked~.custom-control-label::before {
        background-color: $primary;
        box-shadow: none;
        border-color: $primary;
    }
    .custom-control-input:checked~.custom-control-label::after {
        display: block;
    }
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: $color_text_default;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: transparent;
    border-color: $color_text_default;
}

.instructions_list {
    position: relative;
    margin-bottom: 1.75rem;
    .custom-control {
        flex: 1 1 auto;
        width: 1%;
        .custom-control-input {
            z-index: 99;
            left: 0.85rem;
            top: 1.3rem;
        }
        label {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: 0 4rem 0.75rem 0;
            left: 2rem !important;
            width: calc(100% - 4rem);
            &:before {
                left: -3rem !important;
                top: 0.5rem !important;
                box-shadow: none !important;
            }
            &:after {
                left: -2.5rem;
                top: 0.6rem !important;
                border-bottom-color: $success !important;
                border-right-color: $success !important;
                display: none;
            }
            input,
            textarea {
                width: 100%;
                border: none;
                color: $color_text_default;
                outline: none;
                background-color: $white;
                padding: 0.5rem;
                height: auto;
                &.edited {
                    background-color: #f0f0f0;
                }
            }
        }
    }
    .input-group-append {
        position: absolute;
        right: 0px;
        top: 0.9rem;
        .btn-sm {
            padding: 0 0.5rem;
            font-size: 1rem;
            border-radius: 0.3rem !important;
            margin-right: 2rem;
            display: none;
        }
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: $success;
        background-color: $white;
    }
    .custom-control-input:checked~.custom-control-label::after {
        display: block;
    }
}

.instructions_search_list {
    list-style: none;
    li {
        padding: 7px 5px;
        margin-top: 5px;
        border-bottom: solid 1px #ced4da;
        &.selected {
            background-color: $color_smoke_gray;
            border-bottom-color: $white;
            .custom-control-input:checked~.custom-control-label:before {
                border-color: $color_text_default;
            }
            .custom-control-input:checked~.custom-control-label:after {
                border-color: $success;
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

.existing_patients {
    ul {
        margin: 0;
        padding: 0 0 0 2px;
        list-style: none;
        display: block;
        max-height: 205px;
        overflow-y: auto;
        li {
            margin: 0;
            padding: 5px;
            display: block;
            list-style: none;
            position: relative;
            input[type="radio"] {
                position: relative;
                z-index: -1;
            }
            label {
                color: $grey;
                font-weight: 600;
                padding-left: 20px;
                text-transform: capitalize;
                span {
                    color: $primary;
                    font-size: 90%;
                    font-weight: normal;
                }
                &:before {
                    left: -18px;
                    top: 0;
                    width: 24px;
                    height: 24px;
                }
                &:after {
                    left: -9px;
                    top: 2px;
                }
            }
            input:checked~.custom-control-label {
                &::before {
                    background: $success !important;
                }
                &:after {
                    width: 0.5rem;
                    height: 1rem;
                    border-bottom: 0.2rem solid $white;
                    border-right: 0.2rem solid $white;
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.instructions_list2 {
    .custom-checkbox {
        .custom-control-input {
            left: 0rem;
            top: 0rem;
            width: 1.15rem;
            height: 1.15rem;
            z-index: 9;
        }
        .custom-control-label {
            position: relative;
            z-index: 1;
            &:before {
                box-shadow: none !important;
                background-color: transparent !important;
                top: 0.2rem;
            }
            &:after {
                display: none;
                top: 0.35rem;
            }
        }
        .custom-control-input:checked~.custom-control-label:after {
            display: block;
        }
    }
    .instructions_text {
        padding: 0rem 1rem 0.25rem 0.25rem;
        width: calc(100% - 7rem);
        p {
            margin-bottom: 0px;
            outline: none !important;
            border: none !important;
            padding: 0.2rem 0.5rem;
            &.edited {
                background-color: #f0f0f0;
            }
        }
    }
    .action {
        text-align: right;
        padding-top: 0.7rem;
        .btn-sm {
            padding: 0 0.5rem;
            font-size: 1rem;
            border-radius: 0.3rem !important;
            margin-right: 1.75rem;
            display: none;
        }
    }
    &:nth-child(odd) {
        .action {
            text-align: left;
        }
    }
}

.block_habit {
    display: none;
    .custom-select {
        max-width: 100px;
    }
    .custom-control-input:disabled~.custom-control-label {
        color: #999;
    }
    .custom-control-input:disabled~.custom-control-label::before {
        border-color: #999;
    }
    .custom-control-input:disabled~.custom-control-label::after {
        border-color: #999;
    }
    .form-control:disabled {
        background-color: #f9f9f9;
    }
}

.password_wrap {
    width: 100%;
    max-width: 400px;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0px;
    left: unset;
}

.navbar-nav {
    .btn-sm.btn-primary {
        border-color: $white !important;
        color: $primary;
        background-color: $white !important;
        font-weight: 500;
        &:hover {
            background-color: $primary !important;
            border-color: $primary-border !important;
            color: $white;
        }
    }
    .btn-sm.btn-secondary {
        border-color: $primary !important;
        color: $white;
        background-color: transparent !important;
        font-weight: 500;
        font-size: 0.8rem;
        border-width: 0.14rem;
        &:hover {
            background-color: $primary !important;
            border-color: $primary !important;
        }
    }
}


/* ------------------------
 css related to prescribe-medicines
 ---------------------------*/

.doscalfooterbg {
    background-color: $color_text_default;
    border-radius: 0;
    padding: 20px 16px 25px;
    margin-left: -17px;
    margin-right: -17px;
}

.medicine_star .icon_star,
.medicine_star .no-gutters .icon_star {
    width: 16px;
    height: 16px;
    position: relative;
}

.substitutes {
    position: relative;
    height: 100%;
    padding-bottom: 1rem;
    .card {
        height: 100%;
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
        }
    }
}


/*-------------------------------------------------
   Add Appointment Calendar changes
 -------------------------------------------------*/

.calendar-card {
    .table {
        margin-bottom: 0;
        thead {
            background-color: $color_table_header;
            color: $white;
            font-size: 1.1rem;
            font-weight: normal;
            text-transform: capitalize;
            text-align: center;
            tr {
                td {
                    &.control.disabled {
                        cursor: not-allowed;
                    }
                    &.control:not(.disabled) {
                        cursor: pointer;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 1.1rem;
                    color: $grey;
                    font-weight: bold;
                    text-align: center;
                    padding: 1rem 0.35rem 1rem 0.35rem;
                    &.pickadate-disabled {
                        cursor: not-allowed;
                        color: rgba(51, 51, 51, 0.5);
                    }
                    &.pickadate-enabled {
                        cursor: pointer;
                        &:hover {
                            color: $primary;
                        }
                    }
                    &.selected {
                        >span {
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            padding: 5px;
                            background-color: $primary;
                            color: $white;
                            border-radius: 50%;
                        }
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.slots {
    .list-inline-item {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
        .slot {
            width: 100%;
            border-radius: 3px;
            border-color: $slotAvailable;
            color: $color_text_default;
            &:hover,
            &.selectedSlot {
                background: $slotAvailable !important;
                color: $white !important;
            }
            &.booked {
                border-color: $slotBooked !important;
                color: $slotBooked !important;
                cursor: not-allowed;
                &:hover {
                    background: $slotBooked !important;
                    border-color: $slotBooked !important;
                    color: $white !important;
                }
            }
            &.slot-select {
                &:before {
                    content: "";
                    display: inline;
                    width: 0.4rem;
                    height: 0.95rem;
                    border-bottom: 0.1rem solid $slotAvailable;
                    border-right: 0.1rem solid $slotAvailable;
                    transform: rotate(45deg);
                    float: left;
                    top: 3px;
                    position: relative;
                }
                &:hover {
                    background: $slotAvailable !important;
                    border-color: $slotAvailable !important;
                    color: $white !important;
                    &:before {
                        border-bottom-color: $white;
                        border-right-color: $white;
                    }
                }
            }
            &.blocked {
                border-color: $slotBlocked !important;
                color: $slotBlocked !important;
                &:before {
                    border-bottom-color: $slotBlocked;
                    border-right-color: $slotBlocked;
                }
                &:hover {
                    background: $slotBlocked !important;
                    border-color: $slotBlocked !important;
                    color: $white !important;
                    &:before {
                        border-bottom-color: $white;
                        border-right-color: $white;
                    }
                }
            }
            &.lapsed {
                border-color: $slotLapsed !important;
                color: $slotLapsed !important;
                cursor: not-allowed;
                &:hover {
                    background: $slotLapsed !important;
                    border-color: $slotLapsed !important;
                    color: $white !important;
                }
            }
        }
        /*    
     .slot:not(.notbooked){
       &:hover{background: $slotAvailable!important; color: $white!important;}
     }
*/
    }
}

.error_alert span.txtalert {
    color: #ff2626;
    font-size: 14px;
}

.patientConfirmed {
    color: $patientArrived;
}

.patientArrived {
    color: $patientCompleted;
}

.patientCancelled {
    color: $patientCancelled;
}

.patientNoShow {
    color: $patientNoShow;
}


/*--------------------------------------------------
    Multi select Drop Down
  ---------------------------------------------------*/

.bootstrap-select>.dropdown-toggle {
    height: calc(1.8rem + 0.75rem + 2px);
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    background-color: $white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background: $white !important;
    outline: none !important;
    &:focus,
    &:active {
        outline: none !important;
    }
    &.disabled:after {
        display: none;
    }
}

.bootstrap-select .dropdown-menu li a span.check-mark {
    border-bottom: none;
    border-right: none;
    transform: rotate(0deg);
}

.bootstrap-select.show-tick .dropdown-menu span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 5px;
    .table tbody tr td {
        background: $white;
    }
    &.loader {
        background: url(../images/loader.svg) no-repeat center center;
    }
}

.ordersetrow {
    padding-bottom: 1px !important;
    position: relative;
    margin-bottom: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    .btn-outline-primary {
        background: $white;
    }
}

.view_details {
    height: 70px;
}

.inner_table.loader,
.ordersetrow.loader {
    background: url(../images/loader.svg) no-repeat center center;
}

.datepicker.dropdown-menu {
    z-index: 999999 !important;
}

.list-prime-circle {
    list-style: none;
    padding: 0;
    li {
        margin: 0;
        position: relative;
        padding: 0 0 1rem 1.3rem;
        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $color_text_default;
            position: absolute;
            left: 0px;
            top: 8px;
        }
    }
}

.list-icons {
    list-style: none;
    padding: 0;
    li {
        margin: 0;
        position: relative;
        padding: 0 0 1rem 3rem;
        i {
            position: absolute;
            left: 0px;
            top: 0;
            width: 40px;
            height: 30px;
        }
    }
}

.btn-bg-accordion.collapsed {
    background-color: $lightgrey;
    color: $color_text_default !important;
}

.btn-bg-accordion {
    background-color: $color_text_default;
    color: $color_white !important;
    padding: 0.6rem 1rem;
}

.form-control:focus,
.form-control:active {
    box-shadow: none !important;
    border-color: $primary !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:active,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select>select.mobile-device:active+.dropdown-toggle {
    outline: none !important;
}

.select2-container--default .select2-selection--multiple {
    border: solid 1px #ced4da !important;
    &:focus {
        border: solid 1px $primary;
    }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    &:focus {
        outline: none;
        border: solid 1px $primary;
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    min-height: 35px !important;
    &:focus {
        outline: none;
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    top: 10px;
    margin-top: 0px !important;
    font-size: 0.85rem;
    color: #333;
    background: #f3f3f3 !important;
    border: solid 1px #f0f0f0 !important;
}

.select2-results__option {
    padding: 3px 10px !important;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: $white !important;
}

.table-responsive table td {
    white-space: normal;
}

.progress-indicator>li:first-child .bubble:after,
.progress-indicator>li:first-child .bubble:before {
    width: 100% !important;
}

.progress-indicator>li,
.progress-indicator>li.completed {
    color: $color_text_default !important;
}

.logout-countdown {
    .modal-dialog {
        max-width: 400px;
        .modal-content {
            transform: translate(0, 50%);
            .modal-body {
                background: $white;
                padding: 1.5rem;
                .timer {
                    font-size: 4rem;
                    small {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .ant-modal-content {
        .ant-modal-body {
            background: $white;
            padding: 1.5rem;
            .timer {
                font-size: 4rem;
                small {
                    font-size: 1rem;
                }
            }
        }
    }
}

.position-ref {
    .title {
        font-size: 38px;
        height: 100vh;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

.text-basic {
    font-size: 16px !important;
    font-weight: normal !important;
}

.group_wrap {
    padding-left: 0px;
    padding-right: 0px;
}

.w-200 {
    min-width: 200px;
}


/* -------------------------------------------------
    nav-tabs table-tabs
----------------------------------------------------*/

.nav-tabs.table-tabs {
    border-bottom-color: $color_alice_blue;
    .nav-item {
        .nav-link {
            padding: 0.35rem 1rem;
            background-color: $color_alice_blue;
            color: $color_table_header;
            margin-right: 10px;
            border: none;
            //border-top: solid 2px transparent;
            border-left: solid 1px #ccc;
            border-top: solid 1px #ccc;
            border-right: solid 1px #ccc;
            border-radius: 0;
            &.active {
                background-color: $color_table_header;
                color: $white;
                border-left-color: $color_table_header;
                border-right-color: $color_table_header;
                border-top-color: $primary;
                border-top-width: 0.175rem;
            }
        }
    }
}


/* -------------------------------------------------
    Status Dropdown
----------------------------------------------------*/

.status-dropdown {
    position: relative;
    .status-dropdown-content {
        position: absolute;
        display: none;
        width: 250px;
        height: auto;
        left: -250px;
        top: 8px;
        padding-right: 10px;
        ul {
            background: $secondary;
            list-style: none;
            margin: 0;
            padding: 0px;
            li {
                text-align: left;
                margin: 0px;
                padding: 0px;
                border-bottom: solid 1px rgba($white, 0.25);
                a {
                    display: block;
                    padding: 10px;
                    font-size: 90%;
                    color: $color_white;
                    line-height: 1;
                    font-weight: normal;
                    text-decoration: none;
                }
                &:hover {
                    background: $color_text_default;
                }
            }
        }
    }
    &:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        display: none;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 8px solid #fcb2b8;
        left: -8px;
        top: 14px;
    }
    &:hover {
        background: #fcb2b8;
        &:before {
            display: block;
        }
        .status-dropdown-content {
            display: block;
        }
    }
    &:after {
        content: "";
        width: 10px;
        height: 25px;
        position: absolute;
        right: 2px;
        top: 10px;
        display: block;
        background: url("../images/icons.png") no-repeat -45px -100px;
    }
}


/* -------------------------------------------------
    my patient dropdown
----------------------------------------------------*/

.my-patient-dropdown {
    position: fixed;
    width: auto;
    background: $color_white;
    top: 110px;
    right: 0px;
    display: none;
    z-index: 99999;
    .title {
        position: relative;
        padding: 10px 15px;
        font-weight: 500;
        .closed {
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
            opacity: 0.6;
            &::before,
            &::after {
                content: "";
                width: 1rem;
                height: 0.12rem;
                background: $black;
                display: inline-block;
                position: absolute;
                transform: rotate(45deg);
                top: 0.6rem;
                left: -0.6rem;
            }
            &::after {
                transform: rotate(135deg);
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    .my-patient-wrap {
        max-height: calc(100% - 200px);
        overflow-y: auto;
        position: relative;
        table {
            margin: 0px;
            padding: 0px;
            width: 100%;
            tr {
                margin: 0px;
                padding: 0px;
                border-bottom: solid 1px #ccc;
                td {
                    padding: 5px 10px;
                    font-size: 90%;
                    &:first-child {
                        padding-left: 15px;
                    }
                    &:last-child {
                        padding-right: 15px;
                    }
                    .process {
                        border-radius: 50%;
                        background: $primary;
                        color: $color_white;
                        text-align: center;
                        line-height: 25px;
                        width: 25px;
                        height: 25px;
                    }
                    .icon_delete {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.inner_table,
.ordersetrow {
    background: $bg_alice_blue;
    padding: 25px 15px 0px !important;
}

ul.immunization_status {
    display: block;
    margin: 0px;
    padding: 0px;
    li {
        display: inline;
        line-height: 1;
        margin-left: 1rem;
        i {
            width: 12px;
            height: 12px;
            margin-right: 2px;
            display: inline-block;
        }
        &.due-now i {
            background: #d6eaff;
        }
        &.missed i {
            background: #ffcccc;
        }
    }
}

.top-search {
    width: 50%;
    display: inline-block;
    .input-group-addon {
        border: solid 1px #ced4da;
        background: transparent;
        border-radius: 0 0.25rem 0.25rem 0;
        border-left: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
    }
    .form-control {
        border-right: none;
        &:focus+.input-group-addon {
            border-color: $primary;
        }
    }
}

#page_sidebar {
    background-color: $white;
    .patient-section {
        .btn-primary .icon_video {
            position: relative;
            top: 5px;
            &:after {
                @include video_icon($fill_white);
            }
        }
        .btn-outline-secondary {
            .icon_phone {
                position: relative;
                top: 2px;
                height: 12px;
                &:after {
                    @include phone_icon($fill_black);
                }
            }
            &:hover .icon_phone {
                &:after {
                    @include phone_icon($fill_primary);
                }
            }
        }
    }
}


/*--------------------------------------------------
    Nursing Shift Handover
  ---------------------------------------------------*/

.pending_task_handover {
    position: relative;
    z-index: 1;
}

table.pending_task {
    width: 100%;
    tbody {
        tr {
            background: $color_white !important;
            td:first-child {
                width: 32%;
                height: 45px;
                background: $color_alice_blue;
            }
        }
    }
}

.handover_task {
    width: 100%;
    tbody {
        tr {
            background: $color_white !important;
            td:last-child {
                width: 50px;
                height: 45px;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    .copy_handover_task {
        display: block;
        i:before {
            position: relative;
            top: 4px;
        }
    }
}

.w80 {
    width: 80px !important;
}

.d-bullet {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: relative;
    background: $primary;
    margin-right: 6px;
    top: -2px;
    display: inline-block;
}

.sticker_badge {
    position: absolute;
    width: auto;
    top: 0.75rem;
    right: 0.7rem;
    background: $primary;
    color: $white;
    padding: 0.185rem 1.5rem;
    font-size: 0.85rem;
    line-height: 1.5;
    height: 26px;
    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height: 0px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 16px solid $bg_alice_blue;
    }
    &:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0px;
        height: 0px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 16px solid $bg_alice_blue;
    }
}

.c-success {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    border-radius: 50%;
    background: $success;
    position: relative;
    top: 2px;
}

.c-warning {
    width: 0.81rem;
    height: 0.8rem;
    display: inline-block;
    border-radius: 50%;
    background: $warning;
    position: relative;
    top: 2px;
}

.c-primary {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    border-radius: 50%;
    background: $primary;
    position: relative;
    top: 2px;
}

.c-danger {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    border-radius: 50%;
    background: $danger;
    position: relative;
    top: 2px;
}

.color_status {
    i {
        width: 0.85rem;
        height: 0.85rem;
        display: inline-block;
    }
    &.red {
        color: #ff2626;
        i {
            background-color: #ff2626;
        }
    }
}

.date-prev-next {
    position: relative;
    top: 6px;
    margin-right: 10px;
    &:after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 22px;
        background: #ccc;
    }
}


/*--------------------------------------------------
    TEST circle color
  ---------------------------------------------------*/

i.test_color {
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
    &.red {
        background-color: #ff0000;
    }
    &.blue {
        background-color: #0101ff;
    }
    &.cyan {
        background-color: #06ffff;
    }
    &.gray {
        background-color: #979798;
    }
}


/* ############################## Date Time Picker ##########################################*/

.bootstrap-datetimepicker-widget {
    z-index: 99999;
    padding: 4px !important;
    ul li div {
        padding: 0px !important;
        margin: 0px !important;
    }
    table {
        background-color: $white !important;
        margin: 0px !important;
        padding: 0px !important;
        thead tr {
            background-color: #8c8c8c !important;
            th {
                background-color: #8c8c8c !important;
                border-radius: 0px !important;
                border: solid 1px rgba(0, 0, 0, 0.1) !important;
                color: $color_white !important;
                font-weight: 400 !important;
                text-transform: uppercase !important;
                padding: 0.5rem !important;
            }
        }
        tbody tr {
            background-color: $white !important;
            td {
                background: $white !important;
                border-radius: 0px !important;
                border: solid 1px rgba(0, 0, 0, 0.1) !important;
                padding: 0.5rem !important;
                &.active {
                    background-color: #f0f4f7 !important;
                    color: $black !important;
                }
                &.today {
                    background-color: $primary !important;
                    color: $color_white !important;
                    &::before {
                        display: none !important;
                    }
                }
                &:hover {
                    background-color: #f4f4f4 !important;
                }
            }
        }
    }
    .btn.btn-primary {
        background-color: $bg_dark !important;
        border-color: $bg_dark !important;
    }
    .accordion-toggle td {
        background-color: #f9f9f9 !important;
        i {
            font-size: 26px;
            color: $bg_dark;
            cursor: pointer;
        }
    }
    .timepicker tr {
        background-color: $white !important;
        td {
            border: none !important;
        }
    }
    table tfoot td i {
        font-size: 35px;
    }
}

.pagination>.active>a {
    background-color: #d24350 !important;
    border: solid 1px #d24350 !important;
    color: $white !important;
}

.daterangepicker {
    font-family: "Roboto", sans-serif;
    .ranges ul {
        float: left !important;
        width: 525px !important;
        li {
            font-size: 1rem;
            font-weight: 400;
            color: #495057;
            position: relative;
            top: 1px;
            &.active {
                background-color: $primary;
                width: 100%;
            }
        }
    }
    td {
        &.in-range {
            background-color: $bg-grey !important;
        }
        &.active,
        &.active:hover {
            background-color: $color_text_default !important;
        }
    }
    &.show-calendar {
        .ranges {
            margin-top: 0px !important;
            max-width: 225px;
            position: relative;
            z-index: 1;
        }
        .drp-calendar {
            position: relative;
            z-index: 2;
            background: $white;
            min-height: 280px;
        }
    }
}

.add-btn,
.remove-btn {
    font-size: 1.75rem;
    line-height: 1;
    font-weight: 400 !important;
    min-width: 50px;
}

.tooltip-white {
    &.show {
        opacity: 1;
    }
    .arrow:before {
        border-bottom-color: rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
    .arrow:after {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        bottom: -1px;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: $white;
        z-index: 2;
    }
    .tooltip-inner {
        background-color: $white;
        color: $black;
        font-family: Roboto, sans-serif;
        border: solid 1px rgba(0, 0, 0, 0.1);
        padding: 0.4rem 0.8rem;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
        max-width: 400px;
    }
}

.btn-bg-accordion-red {
    background-color: $primary;
    color: $color_white !important;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    .btn.btn-primary {
        background-color: $lightgrey !important;
        color: $primary !important;
        &:hover {
            background-color: $lightgrey !important;
            color: $primary !important;
        }
    }
}

.btn-bg-accordion-red.collapsed {
    background-color: $lightgrey;
    color: $color_text_default !important;
    border-color: $borderColor;
    transition: all 0.5s ease;
    font-size: 1rem;
    &:hover {
        border-color: $primary;
    }
    .btn.btn-primary {
        background-color: $primary !important;
        color: $white !important;
    }
}

.btn-bg-accordion-custom {
    background-color: $color_table_header;
    color: $color_white !important;
    padding: 0.6rem 1rem;
}

.soaplist {
    li {
        padding: 0.75rem 0.5rem 0.3rem 1rem;
        .custom-control {
            min-height: 1.3rem;
        }
        .action {
            width: 80px;
            text-align: left;
            a {
                margin: 0 3px;
            }
        }
        .icon_arrow_up {
            display: inline-block;
            width: 16px;
            height: 16px;
            &:before {
                content: "";
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                @include arrow_icon2($fill_secondary);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .icon_arrow_down {
            display: inline-block;
            width: 16px;
            height: 16px;
            &:before {
                content: "";
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                @include arrow_icon2($fill_secondary);
                background-repeat: no-repeat;
                background-size: cover;
                transform: rotate(-180deg);
            }
        }
        &:first-child .action a:first-child {
            visibility: hidden;
        }
        &:last-child .action a:nth-child(2) {
            visibility: hidden;
        }
    }
}

.fixed-cta {
    position: fixed;
    background-color: $white;
    padding: 12px 0px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 775;
    text-align: center;
    .btn:first-child,
    p {
        margin-left: 250px;
        // padding: .4rem 0;
    }
    &.full {
        .btn {
            margin-left: 0px;
        }
    }
    p {
        margin-bottom: 0px;
    }
    &.homecare-sticky {
        max-width: 1580px;
        margin: 0 auto;
        padding-left: 240px;
    }
}

.soap_sortable {
    .soap_card_no {
        width: 50px;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        color: $white;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: solid 1px #ccc;
        background-color: $color_text_default;
    }
    .widget-panel {
        border-top: solid 1px #ccc;
        background: $color_alice_blue;
        height: 100%;
    }
    .card {
        height: 100%;
        display: flex;
        .card-header {
            background-color: transparent;
            border-bottom: none;
            strong {
                font-weight: 500;
                font-size: 1.1rem;
            }
            .custom-control-label {
                font-size: 1rem;
            }
            &:hover {
                cursor: move;
            }
        }
        ul {
            margin: 0 0.75rem;
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            li {
                padding: 0.75rem 0.5rem 0.75rem 0.25rem;
                counter-increment: item;
                border: none;
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                //  &::before{content: counters(item, ".") "."; display: inline-block;  padding-right: 0.4em; font-weight:500;}
                span {
                    font-weight: 500;
                    display: inline-block;
                    margin-right: 10px;
                }
                strong {
                    font-weight: 500;
                    a {
                        color: $color_text_default;
                        text-decoration: none;
                        display: inline-block;
                        width: calc(100% - 45px);
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            right: 10px;
                            top: 15px;
                            width: 0.7rem;
                            height: 0.7rem;
                            border-bottom: 0.1rem solid #8c8c8c;
                            border-right: 0.1rem solid #8c8c8c;
                            transform: rotate(-135deg);
                            cursor: pointer;
                        }
                        &.collapsed {
                            &::after {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
                .custom-control {
                    min-height: 1.3rem;
                }
            }
        }
    }
    &:last-child {
        .widget-panel {
            border-bottom: solid 1px #ccc;
        }
    }
    .paediatrics_list {
        margin: 10px 0px 0px !important;
        padding: 0px;
        width: calc(100% + 5px);
        li {
            strong {
                margin-left: 1.2rem;
            }
        }
    }
}

.library-images {
    max-height: 430px;
    overflow-y: scroll;
}

@import "modal.scss";

/*--------------------------------------------------
    Media Queries
  ---------------------------------------------------*/

@media (max-width: 1280px) {
    header .navbar-dark .nav-item .nav-link {
        font-size: 0.925rem;
    }
}

@media (max-width: 1024px) {
    .navbar-dark .nav-item .nav-link {
        font-size: 0.85rem;
        padding: 0 0.85rem;
    }
}

@media screen and (max-width: 786px) {
    .table-responsive table td {
        white-space: nowrap;
    }
    .pull-md-right {
        float: right;
    }
}

@media screen and (max-width: 786px) {
    .add_button {
        padding-left: 0px;
        padding-right: 0px;
    }
    .cardbg .top-chart-btn {
        margin-right: 10px;
    }
    .otp-input {
        width: 42px;
    }
    .login-section {
        padding: 0;
        margin-top: 6rem;
        .message {
            width: 100%;
        }
    }
    .login-image {
        display: none;
    }
    .btn.btn-danger.mob-dan {
        text-align: center;
        position: relative;
        width: 3rem;
        padding: 5px;
    }
    .d-sm-block {
        display: block;
        width: 100%;
    }
    .search-card {
        text-align: center;
        .img-fluid {
            display: inline-block;
            max-width: 100px;
        }
    }
    .instructions_list2 .action .btn-sm {
        margin-right: 0.25rem;
    }
}

@media screen and (max-width: 400px) {
    .check_no_know {
        margin-bottom: 15px;
    }
    .add_button {
        padding-left: 15px;
        padding-right: 15px;
    }
    .login-form {
        .btn-primary {
            width: 100%;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (min-width: 1620px) {
    header,
    #main {
        max-width: 1580px;
    }
}


/** SCROLL BAR CSS **/

body {
    overflow: hidden;
    overflow-y: scroll;
}

*::-webkit-scrollbar {
    width: 0.6rem;
}

*::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 10px;
}


/** SCROLL BAR CSS **/

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    border-color: $color_table_header !important;
}


/** loader Animation **/

.spinner {
    margin: 0;
    display: inline-block;
    width: auto;
    text-align: center;
    position: relative;
}

.spinner>div {
    width: 5px;
    height: 5px;
    background-color: $white;
    margin: 0 1px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.white {
    transform: translateY(-12.5%);
    top: 50%;
}

.spinner.black>div {
    background: #333;
}

.spinner.red>div {
    background: $primary;
    height: 20px;
    width: 20px;
    margin: 0 3px;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/** loader Animation **/

.cimsiframe {
    border: 0;
    width: 100%;
    min-height: 450px;
}

.select2-container--default {
    .select2-results {
        .select2-results__option {
            padding: 0.25rem 0.5rem !important;
        }
        .select2-results__option:empty {
            height: 25px !important;
            padding: 0 !important;
        }
        .select2-results__option--highlighted[aria-selected] {
            background-color: $primary;
        }
    }
    .select2-results>.select2-results__options {
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
            background: #fafafa;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ededed;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #ccc;
        }
    }
    .select2-selection--single .select2-selection__rendered {
        opacity: 1 !important;
    }
}

.select2-selection .icon_info {
    width: 16px;
    height: 16px;
}

.select2-results .icon_info {
    width: 20px;
    height: 20px;
}

.interaction_icon_wrap {
    display: inline-block;
    position: relative;
    margin-right: 12px;
}

.interaction_icon_wrap .icon_info {
    width: 18px;
    height: 18px;
    position: relative;
    top: 4px;
}

#top-link {
    position: fixed;
    bottom: -70px;
    right: 0;
    background: $color_text_default;
    width: 44px;
    height: 44px;
    color: $white;
    text-align: center;
    z-index: 9999;
    border-radius: 0.25rem 0.25rem 0 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
    i {
        width: 100%;
        height: 100%;
        &:before {
            content: "";
            display: block;
            position: absolute;
            @include arrow_icon($fill_white);
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }
    &:hover {
        background-color: $primary;
    }
}

.medinfowrap {
    display: inline-flex;
    position: relative;
    top: 6px;
    margin-right: 1rem;
}

.micwrap {
    .mic {
        position: absolute;
        width: 36px;
        height: 36px;
        right: 0px;
        bottom: 0px;
        z-index: 99;
        background: url(../images/ic-mic_gray.gif) no-repeat center center;
        background-size: contain;
        &.mute {
            background-image: url(../images/ic-mic-red.gif);
            width: 36.25px;
        }
    }
    a.edit {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 0;
        bottom: -1px;
        z-index: 99;
        opacity: 0.7;
    }
    input,
    textarea {
        padding-right: 70px;
    }
    &.notes-panel {
        .mic {
            top: 0;
        }
    }
}

.vc_dash_mic {
    position: relative;
    a.mic {
        position: absolute;
        width: 36px;
        height: 36px;
        right: 0;
        top: 0;
        z-index: 99;
        background: url(../images/ic-mic_gray.gif) no-repeat center center;
        background-size: contain;
    }
    a.mic.mute {
        background-image: url(../images/ic-mic-red.gif);
        width: 36.25px;
    }
}

.pdf_slider {
    margin: 0px auto;
    width: 640px;
    // max-height: 500px;
    padding: 0px;
    .slick-slide {
        outline: none;
    }
    .slick-arrow {
        position: absolute;
        z-index: 9999;
        top: 45%;
        border: none;
        background: none;
        width: 30px;
        height: 50px;
        outline: none;
        &::before,
        &::after {
            content: "";
            width: 30px;
            height: 1px;
            background: #999;
            position: absolute;
            left: 0px;
        }
        &.slick-prev {
            left: -40px;
            &::before {
                transform: rotate(-45deg);
                top: 14px;
            }
            &::after {
                transform: rotate(45deg);
                top: 35px;
            }
        }
        &.slick-next {
            right: -40px;
            &::before {
                transform: rotate(45deg);
                top: 14px;
            }
            &::after {
                transform: rotate(-45deg);
                top: 35px;
            }
        }
    }
    .ant-carousel {
        .slick {
            &-list {
                height: calc(100vh - 100px);
            }
            &-track {
                height: inherit;
            }
            &-slide {
                height: inherit;
                >div {
                    // max-height: 500px;
                    height: inherit;
                    >* {
                        height: inherit;
                        display: flex !important;
                        align-items: center;
                    }
                    figure {
                        // max-height: inherit;
                        overflow-y: auto;
                        margin: 0 !important;
                        img {
                            width: 100%;
                            height: auto;
                            margin: 0 !important;
                        }
                    }
                    embed {
                        // height: 500px;
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}

.patient-pic img {
    max-width: 86px;
}

.subjective_info {
    p,
    pre {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #f9f9f9;
        padding: 0.5rem;
    }
}

.list-group-flush .list-group-item {
    background-color: transparent;
}

.btn {
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border-width: 0.075rem;
}

.btn.small {
    font-size: 0.875rem;
}

.btn.btn-faded {
    opacity: 0.7;
}

// .btn.btn-primary,
// .btn.btn-outline-primary:hover {
//   background-color: #d24350 !important;
//   border-color: #d24350 !important;
//   color: $white;
// }
.btn.btn-outline-secondary {
    border-color: #5c5c5c;
    color: #5c5c5c;
}

// .btn.btn-outline-secondary:hover {
//   border-color: #d24350;
//   color: #d24350;
//   background-color: transparent;
// }
// .btn.btn-outline-primary {
//   border-color: #d24350;
//   color: #d24350;
// }
// .btn.btn-danger {
//   background-color: #ff5c5c;
//   border-color: #ff5c5c;
// }
.btn.btn-sm {
    font-size: 0.75rem;
}

.btn.toggle,
.btn.toggle-handle {
    border-radius: 0;
}

.btn.toggle.btn-sm,
.btn.toggle-handle.btn-sm {
    min-height: 25px;
}

.btn.toggle .btn-sm,
.btn.toggle-handle .btn-sm {
    font-size: 1rem;
    line-height: 1.2;
    padding: 2px 1rem;
}

.btn.toggle .toggle-handle,
.btn.toggle-handle .toggle-handle {
    padding: 2px 0.75rem;
}

.btn.toggle .btn-danger,
.btn.toggle-handle .btn-danger {
    background-color: #e74f66;
    border-color: #e74f66;
}

.btn.toggle .btn-success,
.btn.toggle-handle .btn-success {
    background-color: #28bc4e;
    border-color: #28bc4e;
}

.emrfrm #accordionPastVisit .btn.btn-outline-primary:hover {
    background-color: $primary !important;
    color: $white;
    img {
        content: url("../images/copy2.png");
    }
}

.subjective-form .btn.btn-outline-primary {
    color: $primary;
    &:hover {
        background-color: $primary !important;
        color: $white !important;
    }
}

.text-default {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
}

.calendarOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: $white;
}

.list-group-flush .list-group-item.accordion_list a.nostyle-link span {
    width: calc(100% - 25px);
    display: inline-block;
}

.list-group-flush .list-group-item.accordion_list.haslist>a.collapsed::after {
    transform: rotate(45deg);
    top: 15px;
    border-color: #8c8c8c;
}

.list-group-flush .list-group-item.accordion_list.haslist>a::after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 0.7rem;
    height: 0.7rem;
    border-bottom: 0.1rem solid #d24350;
    border-right: 0.1rem solid #d24350;
    transform: rotate(-135deg);
    cursor: pointer;
}

.list-group-flush .list-group-item.accordion_list .haslist_item {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}

.instructions-container {
    .btn.btn-outline-primary:hover {
        background-color: $primary !important;
    }
}

.profile-edit {
    i.icon-edit {
        position: relative;
        top: 2px;
    }
    &:hover {
        i.icon-edit:before {
            @include edit_icon($fill_primary);
        }
    }
}

.copy-prescription {
    .notes-main {
        width: 100%;
        max-width: 100%;
    }
    .ant-modal-body {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
    .ant-modal-footer {
        padding: 1rem 1rem;
    }
}

.card.text-boxes {
    height: 100%;
}

.copy-prescription-section {
    height: 60vh;
}

.copy-check {
    position: absolute;
    bottom: 20px;
    right: 15px;
}

pre.cb-innerwrapper {
    font-size: 14px;
    color: rgb(92, 92, 92);
    margin-bottom: 0;
}

.accordion.development {
    .panel {
        .panel-heading {
            margin: 15px 0px 0px;
            a {
                color: $color_text_default;
                text-decoration: none !important;
                position: relative;
                background: #f8f8f8;
                border-radius: 5px 5px 0px 0px;
                border: 1px solid rgba(0, 0, 0, 0.125);
                font-size: 1.05rem;
                padding: 0.6rem 30px 0.6rem 0.6rem;
                span {
                    display: inline-block;
                    width: 120px;
                    text-align: left;
                    margin-left: 40px;
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 12px;
                    top: 16px;
                    width: 0.7rem;
                    height: 0.7rem;
                    border-bottom: 0.1rem solid $color_table_header;
                    border-right: 0.1rem solid $color_table_header;
                    transform: rotate(-135deg);
                    cursor: pointer;
                }
                &.collapsed {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    &::after {
                        top: 12px;
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .panel-body {
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-top: none;
            position: relative;
            padding: 0 1rem;
            .panel-body-block {
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                padding: 15px 25px 15px 0;
            }
            ul {
                margin: 0px;
                padding: 0px;
                list-style: none;
                li {
                    padding: 6px 0;
                }
            }
            &::after {
                content: "";
                border-left: solid 1px rgba(0, 0, 0, 0.125);
                width: 1px;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0px;
            }
            .row {
                .col-6:nth-child(1) .panel-body-block,
                .col-6:nth-child(2) .panel-body-block {
                    border-top: none;
                }
            }
        }
        &.green {
            .panel-heading a {
                background: #ddefc9;
                border-color: #afc49c;
                color: #778169;
            }
            .panel-body {
                border-color: #afc49c;
                .panel-body-block {
                    border-color: #afc49c;
                }
                &::after {
                    border-color: #afc49c;
                }
            }
        }
        &.yellow {
            .panel-heading a {
                background: #f9edcd;
                border-color: #e6c472;
                color: #8e613e;
            }
            .panel-body {
                border-color: #e6c472;
                .panel-body-block {
                    border-color: #e6c472;
                }
                &::after {
                    border-color: #e6c472;
                }
            }
        }
        &.red {
            .panel-heading a {
                background: #ffdbde;
                border-color: #e99fa5;
                color: #d24350;
            }
            .panel-body {
                border-color: #e99fa5;
                .panel-body-block {
                    border-color: #e99fa5;
                }
                &::after {
                    border-color: #e99fa5;
                }
            }
        }
    }
}

// legend_unit_label changes

/* ul.legend_unit_label.chart-Chang{
  padding: 0px 0px 10px 170px;
 }
 ul.legend_unit_label.chart-Chang1{
  padding: 0px 0px 10px 110px;
  } */

ul.legend_unit_label {
    position: relative;
    z-index: 1;
    margin: 0px;
    padding: 0px 0px 10px;
    width: 100%;
    text-align: center;
    list-style: none;
    li {
        display: inline-block;
        font-size: 13px;
        margin: 0 15px;
        span {
            width: 20px;
            height: 13px;
            border: solid 1px #f0f0f0;
            display: inline-block;
            margin-right: 6px;
            position: relative;
            top: 2px;
            &.fst {
                background-color: #ec003b;
            }
            &.thrd {
                background-color: #ffec00;
            }
            &.fif {
                background-color: #5ac9ef;
            }
            &.nint {
                background-color: #aaaaaa;
            }
        }
    }
}

.vital-graph-legends ul.legend_unit_label {
    position: relative;
    z-index: 1;
    margin: 0px;
    padding: 0px 0px 10px;
    text-align: right;
    list-style: none;
    li {
        display: inline-block;
        margin: 0 15px;
        font-size: 13px;
        span {
            width: 12px;
            height: 12px;
            border-radius: 7px;
            &.normal {
                background-color: rgb(0, 168, 77);
            }
            &.abnormal {
                background-color: rgb(245, 153, 28);
            }
            &.critical {
                background-color: rgb(255, 0, 0);
            }
        }
    }
}

.chartCanvas {
    ul.legend_unit_label {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        left: 0px;
        li {
            margin: 0 6px;
            span {
                width: 18px;
                height: 8px;
                top: 0px;
                &.gray {
                    background-color: rgb(102, 102, 102);
                }
                &.pink {
                    background-color: rgb(255, 82, 235);
                }
                &.blue {
                    background-color: rgb(9, 201, 239);
                }
                &.white {
                    background-color: #fff;
                }
                &.round {
                    border-radius: 6px;
                    height: 11px;
                    width: 11px;
                    top: 2px;
                    border-width: 1px;
                    border-color: rgb(102, 102, 102);
                }
            }
        }
    }
}

.chartCanvas2 {
    span {
        width: 18px;
        height: 8px;
        top: 0px;
        &.gray {
            background-color: rgb(102, 102, 102);
        }
        &.pink {
            background-color: rgb(255, 82, 235);
        }
        &.blue {
            background-color: rgb(9, 201, 239);
        }
        &.white {
            background-color: #fff;
        }
        &.target-blue {
            background-color: #79a0be;
        }
        &.target-eighty {
            background-color: #b6b6b6;
        }
    }
}

.chartCanvas2 {
    ul.legend_unit_label {
        //position: absolute;
        // z-index: 1;
        //bottom: -11px;
        //left: 15px;
        //display: inline;
        li {
            margin: 0 6px;
            span {
                width: 18px;
                height: 8px;
                top: 0px;
                &.gray {
                    background-color: rgb(102, 102, 102);
                }
                &.pink {
                    background-color: rgb(255, 82, 235);
                }
                &.blue {
                    background-color: rgb(9, 201, 239);
                }
                &.white {
                    background-color: #fff;
                }
                &.target-blue {
                    background-color: #79a0be;
                }
                &.target-eighty {
                    background-color: #b6b6b6;
                }
                &.round {
                    border-radius: 6px;
                    height: 11px;
                    width: 11px;
                    top: 2px;
                    border-width: 1px;
                    border-color: rgb(102, 102, 102);
                }
            }
        }
    }
}

.paediatrics_chart {
    background: #fafafa;
    border: solid 1px #f0f0f0;
    .zoomChartBtn {
        position: absolute;
        z-index: 9999;
        right: 12px;
        top: 6px;
        cursor: pointer;
        background: url(../images/maximize.svg) no-repeat center center;
        height: 24px;
        width: 24px;
        background-size: contain;
        opacity: 0.4;
        &:hover {
            opacity: 0.6;
        }
    }
    .printChart {
        position: absolute;
        display: none;
        bottom: -1rem;
        right: 12px;
        z-index: 99;
    }
    .chartjs-render-monitor {
        padding: 0px 0px 35px 0px;
        // background: #fafafa; border:solid 1px #f0f0f0
    }
    &.zoomChart {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100% !important;
        height: 100% !important;
        z-index: 999;
        .chartjs-render-monitor {
            padding: 15px 0px 45px 0px;
        }
        .zoomChartBtn {
            background-image: url(../images/minimize.svg);
        }
        .printChart {
            display: block;
        }
    }
    ul.legend_label {
        list-style: none;
        position: absolute;
        z-index: 9;
        margin: 0px;
        padding: 0px;
        right: 10px;
        width: 120px;
        height: 100%;
        box-sizing: border-box;
        li {
            width: 100%;
            position: absolute;
            // background: #fff; border:solid 1px #fafafafa;
            padding: 2px 5px;
            box-sizing: border-box;
            font-size: 14px;
        }
    }
    ul.legend_unit_label {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        left: 0px;
    }
}

.icon-hover-white:hover {
    i {
        &.icon {
            &-copy:before {
                @include copy_icon($fill_white);
            }
        }
    }
}


/* ecg css */

.ecg-bpm-value img {
    width: 15px;
}

.ecg__body {
    background-color: #e2e2e2;
}

.ecg__body aside {
    background-color: $white;
    height: 72vh;
}

.ecg_right {
    height: 72vh;
}

.ecg_right object {
    width: 100%;
    height: 100%;
}

.bpm-sub sub {
    font-size: 16px;
    font-weight: normal;
    margin-left: 8px;
}

.bpm-sub {
    color: #666;
}

.aside-data.active h4 {
    color: $primary;
}

.aside-copy {
    width: 30%;
    overflow-y: auto;
}

.aside-data p {
    margin-bottom: 0;
}

.aside-data {
    cursor: pointer;
}

.aside-data * {
    pointer-events: none;
}

.aside-data.active {
    color: $primary;
    background-color: #e2e2e2;
    border-left: 5px solid #959595;
}

.navdrop_itemwrapper.pubnub {
    max-height: 300px;
    overflow: auto;
}

.corrected_age {
    background: #f4f4f4;
    color: #495057;
    border: solid 1px #ced4da;
    padding: 0.55rem 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 0.25rem;
    height: calc(1.85em + 0.75rem + 2px);
    span {
        color: #d24350;
    }
}

.milestone_tab_panel {
    ul.nav-tabs {
        display: block;
        border-bottom: none;
        li {
            display: inline-block;
            margin-right: 5px;
            a {
                padding: 8px 18px;
                border: solid 1px #ccc;
                text-decoration: none;
                color: #555;
                position: relative;
                &.active {
                    background: #f0f4f7;
                    font-weight: 500;
                    border-top-width: 3px;
                    border-top-color: #d24350;
                    font-size: 99%;
                    &::before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-width: 15px 10px 0 10px;
                        border-style: solid;
                        border-color: #f0f4f7 transparent transparent transparent;
                        left: 50%;
                        margin-left: -5px;
                        bottom: -15px;
                        z-index: 2;
                    }
                    &::after {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-width: 15px 10px 0 10px;
                        border-style: solid;
                        border-color: #ccc transparent transparent transparent;
                        left: 50%;
                        margin-left: -5px;
                        bottom: -16px;
                        z-index: 1;
                    }
                }
                &.current_age {
                    background: #d24350 !important;
                    color: #fff;
                    border-color: #d24350;
                    &::before {
                        border-color: #d24350 transparent transparent transparent;
                    }
                }
                &.delayed {
                    border-color: #d24350;
                    color: #d24350;
                }
            }
        }
    }
    .tab-content {
        margin-top: 6px;
        .tab-pane {
            border: 1px solid rgba(0, 0, 0, 0.125);
            position: relative;
            padding: 0 1rem;
            .panel-body-block {
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                padding: 25px 25px 15px 0;
            }
            ul {
                margin: 0px;
                padding: 0px;
                list-style: none;
                li {
                    padding: 6px 0;
                }
            }
            &::after {
                content: "";
                border-left: solid 1px rgba(0, 0, 0, 0.125);
                width: 1px;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0px;
            }
            .row {
                .col-6:nth-child(1) .panel-body-block,
                .col-6:nth-child(2) .panel-body-block {
                    border-top: none;
                }
            }
        }
    }
}

ul.milestone_legend {
    list-style: none;
    margin: 0px 0px 10px;
    padding: 0px;
    text-align: right;
    li {
        display: inline-block;
        margin-left: 15px;
        span {
            width: 18px;
            height: 11px;
            display: inline-block;
            border: solid 2px #d24350;
        }
        &.current_age span {
            background: #d24350;
        }
        &.delayed_milestones span {
            background: #fff;
        }
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label.taper::after {
    width: 6px;
    height: 11px;
    top: 0.3rem;
    left: -1.2rem;
}

.model_head {
    font-size: 17px;
    color: #5c5c5c;
    padding-top: 20px;
}

.model_bg_blue {
    background-color: #f0f4f7;
    border: #e3e3e4 1px solid;
    padding: 20px 6px 0px 6px;
}

.top_head {
    width: 100%;
    padding: 10px 0;
}

.top_head h2 {
    float: left;
    padding: 0;
}

.top_head span {
    float: right;
}

.table_poup {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border: #ccc 2px solid;
}

.table_poup .tr {
    display: table-row;
}

.table_poup .tr .table_td {
    display: table-cell;
    vertical-align: top;
    border-right: #ccc 1px solid;
}

// .table_poup .tr .table_td:last-child {
//   padding-bottom:10px !important;
// }
.table_poup p {
    padding-left: 12px !important;
}

.table_poup .tr .table_td h2 {
    background-color: #8b8b8b;
    padding: 15px;
    color: #ffffff;
    font-size: 16px;
    margin: 0;
}

.table_poup .tr .table_td>.row {
    padding: 8px 10px 0px 10px !important;
}

.table_poup .tr .table_td .ant-form-item {
    margin: 0 !important;
}

.has-error {
    .form-control {
        box-shadow: none !important;
        border-color: #d24350 !important;
    }
}

.text-bold {
    font-weight: 700;
}

.text-small {
    font-size: 14px;
}

.button_underline button {
    background: none;
    border: none;
    font-weight: 700;
}

.padding_top {
    padding-top: 10px;
}

.padding_tp {
    padding-top: 10px;
}

.btn_filter {
    border: none;
    margin-left: 5px;
    background: url(../images/filer-icon.svg) no-repeat center center transparent;
    background-size: cover;
    width: 16px;
    height: 16px;
}

.padding_top_1 {
    padding-top: 9px;
}

.table_border {
    background-color: #f6f8fa;
    border-collapse: collapse;
}

.table_border tr:first-child td {
    font-weight: 700;
    font-size: 14px;
}

.table_border tr td {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.table_border tr td:first-child {
    text-align: center;
    width: 62px;
}

.table_border tr td:nth-child(3) {
    width: 86px;
}

.table_border tr td:nth-child(4) {
    width: 108px;
}

.schedule_link {
    padding: 8px 0 0 5px;
    text-decoration: underline;
}

.padding_lef_corr {
    padding-left: 15px;
    border-left: solid 1px #d24350;
}

.padding_lef_corr label {
    padding-bottom: 0;
    margin-bottom: 0;
}

.chart_width {
    width: 98%;
    margin-left: 2px;
}

.padd_5 {
    padding: 0px;
    margin-left: -5px;
}

.font_rt h4 {
    font-size: 14px;
}

.ant-tooltip-inner {
    color: #000000;
    font-size: 14px;
    color: #999;
}

.date_field .ant-form-item {
    width: 55%;
    display: inline-block;
    vertical-align: top;
}

.date_field span.date {
    padding: 11px 0 0 5px;
    display: inline-block;
    vertical-align: top;
}

.button_ant_new button.ant-btn {
    padding: 0 8px;
    border: none;
    margin-top: 17px;
    font-weight: 500;
    box-shadow: none;
    background: none;
    font-size: 15px;
    color: #000000;
}

.enter_patient input {
    background-color: rgb(255, 230, 232);
    border: 1px solid #d24350;
}

.btn_sequence {
    border: none;
    display: block;
    margin: 0 auto;
    background: url(../images/drag.svg) no-repeat center center transparent;
    background-size: cover;
    width: 21px;
    height: 16px;
}

// .insullin_taken {
//   border: solid 1px #fef1c7;
//   background: #fff4d0;
//   border-radius: 20px;
//   display: inline-block;
//   padding: 4px 15px;
//   position: relative;
//   margin-left: 30px;
//   &.post_meal {
//     border-color: #f5e1d4;
//     background-color: #fce8db;
//   }
//   &.bed_time {
//     border-color: #d2d2d2;
//     background-color: #e2e2e2;
//   }
// }
// .insullin_legend {
//   .legend_code {
//     width: 18px;
//     height: 14px;
//     border: solid 1px #fef1c7;
//     background: #fff4d0;
//     display: inline-block;
//     margin-right: 5px;
//     &.post_meal {
//       border-color: #f5e1d4;
//       background-color: #fce8db;
//     }
//     &.bed_time {
//       border-color: #d2d2d2;
//       background-color: #e2e2e2;
//     }
//   }
// }
// .insullin_legend_new {
//   .legend_code {
//     width: 35px;
//     height: 35px;
//     border: solid 1px #fef1c7;
//     background: #fff4d0;
//     display: inline-block;
//     margin-right: 5px;
//     font-weight: bold;
//     text-align: center;
//     line-height: 35px;
//     &.low {
//       border-color: #2aa305;
//       border-radius: 50px;
//       background-color: #00b33c;
//       color: #fff !important;
//     }
//     &.moderate {
//       border-color: #bfc200;
//       border-radius: 50px;
//       background-color: #eedd41;
//     }
//     &.high {
//       border-color: #d24350;
//       border-radius: 50px;
//       background-color: #d24350;
//       color: #fff !important;
//     }
//   }
//   .legend_code_bottom {
//     width: 20px;
//     height: 20px;
//     border: solid 1px #fef1c7;
//     background: #fff4d0;
//     display: inline-block;
//     margin-right: 5px;
//     vertical-align: middle;
//     &.low {
//       border-color: #2aa305;
//       border-radius: 50px;
//       background-color: #00b33c;
//     }
//     &.moderate {
//       border-color: #bfc200;
//       border-radius: 50px;
//       background-color: #eedd41;
//     }
//     &.high {
//       border-color: #d24350;
//       border-radius: 50px;
//       background-color: #d24350;
//     }
//   }
//   .middle-align {
//     vertical-align: middle;
//   }
// }
.graph-font {
    font-size: 26px;
}

.table.legend {
    width: 100% !important;
    th {
        // width:103px;
        border-right: 1px solid #b5babe !important;
        border-top: none !important;
        border-bottom: 1px solid #b5babe !important;
    }
    td {
        border-top: none !important;
        border-right: 1px solid #b5babe !important;
        border-bottom: 1px solid #b5babe !important;
    }
}

.table.icons {
    width: 96% !important;
    th {
        //width:220px !important;
        border-top: none !important;
    }
    td {
        border-top: none !important;
    }
}

.sortableHelper {
    z-index: 9999;
}

.table_key_findings_sequence.table_border tr td:first-child {
    text-align: initial;
}

.blood__sugarChart.legend th,
.blood__sugarChart.legend td {
    border: 0!important;
    font-size: 14px;
}

.blood__sugarChart.legend th {
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px !important;
}

// new css
////
//////
.insullin_legend {
    .legend_code {
        width: 18px;
        height: 14px;
        border: solid 1px #fef1c7;
        background: #fff4d0;
        display: inline-block;
        margin-right: 5px;
        &.post_meal {
            border-color: #f5e1d4;
            background-color: #fce8db;
        }
        &.bed_time {
            border-color: #d2d2d2;
            background-color: #e2e2e2;
        }
    }
}

.insullin_legend_new {
    .legend_code {
        width: 35px;
        height: 35px;
        border: solid 1px #fef1c7;
        background: #fff4d0;
        display: inline-block;
        margin-right: 5px;
        font-weight: bold;
        text-align: center;
        line-height: 35px;
        &.low {
            border-color: #2aa305;
            border-radius: 50px;
            background-color: #00b33c;
            color: #fff !important;
        }
        &.moderate {
            border-color: #bfc200;
            border-radius: 50px;
            background-color: #eedd41;
        }
        &.high {
            border-color: #d24350;
            border-radius: 50px;
            background-color: #d24350;
            color: #fff !important;
        }
    }
    .legend_code_bottom {
        width: 20px;
        height: 20px;
        border: solid 1px #fef1c7;
        background: #fff4d0;
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
        &.low {
            border-color: #2aa305;
            border-radius: 50px;
            background-color: #00b33c;
        }
        &.moderate {
            border-color: #bfc200;
            border-radius: 50px;
            background-color: #eedd41;
        }
        &.high {
            border-color: #d24350;
            border-radius: 50px;
            background-color: #d24350;
        }
    }
    .middle-align {
        vertical-align: middle;
    }
}

// .blood__sugarChart.legend th, .blood__sugarChart.legend td {
//   border: 0!important;
//   font-size: 14px;
// }
// .blood__sugarChart.legend th {
//   font-size: 14px;
//   padding-top: 20px;
// }
// .w-33 {
//   width: 26%!important;
//   float: left;
// }
// .w-67 {
//   width: 74%!important;
//     float: left;
// }
// .new_row {
//   display: block;
//    width: 100%;
// }
// .w-40 {
//   width: 33.33%!important;
//   float: left;
// }
.fix-height-right {
    .chart_width_iner {
        canvas {
            // max-height: 250px !important;   
            // min-height: 250px !important;
        }
        h4 {
            padding: 5px 15px 5px;
        }
        .chartCanvas {
            canvas {
                padding: 0 0 12px 24px;
                margin-left: -10px;
            }
        }
    }
    &.col-md-12 {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 4px;
        .w-100 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.fix-height-left {
    .chart_width_iner {
        background-color: #f3f3f3;
        min-height: calc(100% - 10px) !important;
        // canvas{
        //   min-height:500px !important;
        //   max-height:500px !important;
        // }
    }
}

.active_link {
    background-color: #d24350 !important;
    color: #fff !important;
}

.inactive_link {
    background-color: white !important;
    color: #495057 !important;
}

.daterangepicker .ranges .main_ul li:hover {
    background-color: #ccc !important;
}

.control {
    font-weight: 600;
    position: relative;
    left: 58px;
    top: 9px;
    font-size: 13px;
    padding-bottom: 3px
}

.left-pad {
    padding-bottom: 0px !important
}

.padd {
    padding-bottom: 0px !important;
}

@media (min-width: 992px) {
    .fix-height-right {
        .chart_width_iner {
            canvas {
                // max-height: 165px !important;
                // min-height: 165px !important;
            }
            h4 {
                padding: 5px 15px 5px;
            }
            .chartCanvas {
                canvas {
                    padding: 0 0 12px 24px;
                    margin-left: -10px;
                }
            }
        }
        &.col-md-12 {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0px;
            padding-right: 4px;
            .w-100 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .chartCanvas {
        ul.legend_unit_label {
            bottom: -10px;
        }
    }
    .insullin_legend_new {
        .legend_code {
            width: 30px !important;
            height: 30px !important;
            //line-height: 25px !important;
        }
    }
    .blood__sugarChart.legend th {
        font-size: 12px;
        padding-bottom: 0px !important;
    }
    .chartCanvasWrapper .chartCanvas h4 {
        font-size: 13px;
    }
    .chart_width_iner h4 {
        font-size: 13px;
    }
    .padd {
        padding-bottom: 10px !important;
    }
}

@media (min-width: 1140px) {
    .fix-height-right {
        .chart_width_iner {
            canvas {
                //  max-height: 175px !important;
                //  min-height: 175px !important;
            }
            h4 {
                padding: 5px 15px 5px;
            }
            .chartCanvas {
                canvas {
                    padding: 0 0 12px 24px;
                    margin-left: -10px;
                }
            }
        }
        &.col-md-12 {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0px;
            padding-right: 4px;
            .w-100 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .chartCanvas {
        ul.legend_unit_label {
            bottom: -10px;
        }
    }
    //  .insullin_legend_new {
    //    .legend_code {
    //      width: 25px !important;
    //      height: 25px !important;
    //     line-height: 25px !important;
    //    }
    //  }
    .blood__sugarChart.legend th {
        font-size: 13px;
        padding-bottom: 0px !important;
        // padding-left:24px;
    }
    .chartCanvasWrapper .chartCanvas h4 {
        font-size: 13px;
    }
    .chart_width_iner h4 {
        font-size: 13px;
    }
    .insullin_legend_new .legend_code {
        width: 32px !important;
        height: 32px !important;
    }
    .control {
        font-weight: 600;
        position: relative;
        left: 58px;
        top: 9px;
        font-size: 13px;
        padding-bottom: 3px
    }
    .left-pad {
        padding-bottom: 0px !important
    }
    .padd {
        padding-bottom: 0px !important;
    }
    .graphs_chart {
        height: 178px !important;
    }
}

@media (min-width: 1280px) {
    .fix-height-right {
        .chart_width_iner {
            canvas {
                //  max-height: 193px !important;
                //  min-height: 193px !important;
            }
            h4 {
                padding: 5px 15px 5px;
            }
            .chartCanvas {
                canvas {
                    padding: 0 0 12px 24px;
                    margin-left: -10px;
                }
            }
        }
        &.col-md-12 {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0px;
            padding-right: 4px;
            .w-100 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .chartCanvasWrapper .chartCanvas h4 {
        font-size: 15px;
    }
    .chart_width_iner h4 {
        font-size: 15px;
    }
}

@media (min-width: 1440px) {
    .fix-height-right {
        .chart_width_iner {
            canvas {
                // max-height: 275px !important;
                // min-height: 275px !important;
            }
            h4 {
                padding: 5px 15px 5px;
            }
            .chartCanvas {
                canvas {
                    padding: 0 0 12px 24px;
                    margin-left: -10px;
                }
            }
        }
        &.col-md-12 {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0px;
            padding-right: 4px;
            .w-100 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .blood__sugarChart.legend th {
        font-size: 15px;
        padding-bottom: 0px !important;
        padding-left: 24px;
    }
    .chartCanvasWrapper .chartCanvas h4 {
        font-size: 16px;
    }
    .chart_width_iner h4 {
        font-size: 16px;
    }
    .graphs_chart {
        height: 230px !important;
    }
}

@media (min-width: 900px) {
    .graphs_chart {
        height: 145px !important;
    }
    ul.chart_filter {
        margin-left: -95px;
        margin-right: -102px;
    }
    ul.chart_filter li .btn {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

@media (min-width: 930px) {
    .graphs_chart {
        height: 150px !important;
    }
}

@media (min-width: 960px) {
    .graphs_chart {
        height: 154px !important;
    }
}

@media (min-width: 992px) {
    .graphs_chart {
        height: 155px !important;
    }
    ul.chart_filter {
        margin-left: -47px;
        margin-right: -96px;
    }
    ul.chart_filter li .btn {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

@media (min-width: 1010px) {
    .graphs_chart {
        height: 158px !important;
    }
}

@media (min-width: 1020px) {
    .graphs_chart {
        height: 159px !important;
    }
}

@media (min-width: 1040px) {
    .graphs_chart {
        height: 162px !important;
    }
}

@media (min-width: 1060px) {
    .graphs_chart {
        height: 165px !important;
    }
}

@media (min-width: 1080px) {
    .graphs_chart {
        height: 168px !important;
    }
    ul.chart_filter {
        margin-left: -47px;
        margin-right: -96px;
    }
    ul.chart_filter li .btn {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }
}

@media (min-width: 1100px) {
    .graphs_chart {
        height: 171px !important;
    }
}

@media (min-width: 1120px) {
    .graphs_chart {
        height: 175px !important;
    }
}

@media (min-width: 1140px) {
    .graphs_chart {
        height: 178px !important;
    }
    ul.chart_filter {
        margin-left: -47px;
        margin-right: -96px;
    }
    ul.chart_filter li .btn {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

@media (min-width: 1160px) {
    .graphs_chart {
        height: 180px !important;
    }
}

@media (min-width: 1180px) {
    .graphs_chart {
        height: 183px !important;
    }
}

@media (min-width: 1200px) {
    .graphs_chart {
        height: 186px !important;
    }
}

@media (min-width: 1220px) {
    .graphs_chart {
        height: 189px !important;
    }
    ul.chart_filter {
        margin-left: -47px;
        margin-right: -96px;
    }
    ul.chart_filter li .btn {
        padding-left: 18px !important;
        padding-right: 18px !important;
    }
}

@media (min-width: 1240px) {
    .graphs_chart {
        height: 192px !important;
    }
}

@media (min-width: 1260px) {
    .graphs_chart {
        height: 195px !important;
    }
}

@media (min-width: 1280px) {
    .graphs_chart {
        height: 201px !important;
    }
    ul.chart_filter {
        margin-left: -47px;
        margin-right: -75px;
    }
}

@media (min-width: 1300px) {
    .graphs_chart {
        height: 205px !important;
    }
}

@media (min-width: 1320px) {
    .graphs_chart {
        height: 208px !important;
    }
}

@media (min-width: 1340px) {
    .graphs_chart {
        height: 210px !important;
    }
}

@media (min-width: 1366px) {
    .graphs_chart {
        height: 214px !important;
    }
}

@media (min-width: 1380px) {
    .graphs_chart {
        height: 216px !important;
    }
}

@media (min-width: 1400px) {
    .graphs_chart {
        height: 219px !important;
    }
}

@media (min-width: 1420px) {
    .graphs_chart {
        height: 222px !important;
    }
}

@media (min-width: 1440px) {
    .graphs_chart {
        height: 226px !important;
    }
}

@media (min-width: 1480px) {
    .graphs_chart {
        height: 233px !important;
    }
}

@media (min-width: 1500px) {
    .graphs_chart {
        height: 235px !important;
    }
}

@media (min-width: 1536px) {
    .graphs_chart {
        height: 241px !important;
    }
}

@media (min-width: 1570px) {
    .graphs_chart {
        height: 246px !important;
    }
}

@media (min-width: 1600px) {
    .graphs_chart {
        height: 250px !important;
    }
}

@media (min-width: 1640px) {
    .graphs_chart {
        height: 256px !important;
    }
}

@media (min-width: 1680px) {
    .graphs_chart {
        height: 262px !important;
    }
}

@media (min-width: 1720px) {
    .graphs_chart {
        height: 268px !important;
    }
    ul.chart_filter {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (min-width: 1770px) {
    .graphs_chart {
        height: 274px !important;
    }
}

@media (min-width: 1830px) {
    .graphs_chart {
        height: 289px !important;
    }
}

@media (min-width: 1880px) {
    .graphs_chart {
        height: 292px !important;
    }
}

@media (min-width: 1920px) {
    .graphs_chart {
        height: 298px !important;
    }
}

@media (min-width: 2000px) {
    .graphs_chart {
        height: 325px !important;
    }
}

@media (min-width: 2200px) {
    .graphs_chart {
        height: 340px !important;
    }
}

@media (min-width: 2300px) {
    .graphs_chart {
        height: 355px !important;
    }
}

@media (min-width: 2400px) {
    .graphs_chart {
        height: 370px !important;
    }
}

@media (min-width: 2500px) {
    .graphs_chart {
        height: 385px !important;
    }
}

@media (min-width: 2560px) {
    .graphs_chart {
        height: 385px !important;
    }
}

@media (min-width: 2600px) {
    .graphs_chart {
        height: 400px !important;
    }
}

@media (min-width: 2700px) {
    .graphs_chart {
        height: 415px !important;
    }
}

@media (min-width: 2800px) {
    .graphs_chart {
        height: 430px !important;
    }
}

@media (min-width: 2900px) {
    .graphs_chart {
        height: 445px !important;
    }
}

@media (min-width: 3000px) {
    .graphs_chart {
        height: 460px !important;
    }
}

.graphs_chart {
    background: #f3f3f3;
    border: solid 1px #f0f0f0;
    height: 290px;
    .zoomChartBtn {
        position: absolute;
        z-index: 9999;
        right: 40px;
        top: 10px;
        cursor: pointer;
        background: url(../images/maximize.svg) no-repeat center center;
        height: 17px;
        width: 20px;
        background-size: contain;
        opacity: 0.4;
        &:hover {
            opacity: 0.6;
        }
    }
    .printChart {
        position: absolute;
        display: none;
        bottom: -1rem;
        right: 12px;
        z-index: 99;
    }
    .chartjs-render-monitor {
        padding: 0px 0px 35px 0px;
        // background: #fafafa; border:solid 1px #f0f0f0
    }
    &.zoomChart {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100% !important;
        height: 100% !important;
        z-index: 999;
        .chartjs-render-monitor {
            padding: 15px 0px 45px 0px;
        }
        .zoomChartBtn {
            background-image: url(../images/minimize.svg);
        }
    }
}

.font-cus {
    font-size: 1rem;
    color: #5c5c5c;
}

.margin-prev {
    margin-bottom: 0rem !important;
}

@media (max-width:1280px) {
    &.auto-width {
        .nav-item {
            .nav-link {
                &.gynae {
                    padding-left: 1.5rem !important;
                    padding-right: 1.5rem !important;
                }
            }
        }
    }
}

.custom-cheif {
    .ant-select-selector {
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        border-radius: 0px !important;
    }
    &.break-word {
        .ant-select-selection-item-content {
            white-space: normal !important;
            word-break: break-all;
        }
        .ant-select-selection-overflow-item {
            margin-right: 7px;
        }
        &.ant-select-multiple .ant-select-selection-search-input {
            max-width: 98% !important;
        }
    }
    &.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        background: #f5f5f5 !important;
        border: 1px solid #ccc !important;
        color: #5c5c5c !important;
    }
}

@media (max-width:1240px) {
    .myhealthcare-tabs {
        .nav-item {
            .nav-link {
                padding: 0.2rem 0.2rem;
            }
        }
    }
}

.patient-queue-calendar {
    .dashboard_calendar {
        .ant-picker-input {
            background-color: #eff0f0;
            width: 100% !important;
        }
        .ant-picker-focused {
            border-color: #eff0f0;
            background-color: #eff0f0;
        }
        .ant-picker-input>input {
            color: #5c5c5c;
            font-weight: 500;
        }
        .ant-picker:hover {
            border-color: #eff0f0;
        }
        .ant-picker {
            padding: 0px !important;
            background-color: #eff0f0;
            border: none !important;
        }
        svg {
            color: #5c5c5c;
            font-size: 16px;
        }
    }
    .arrow-left {
        position: absolute;
        top: 4px;
        left: 14px;
    }
    .arrow-right {
        position: absolute;
        top: 4px;
        right: 14px;
    }
    .prev {
        margin-left: 25px;
        font-weight: 500;
    }
    .prev:hover {
        color: #d24350;
    }
    .next {
        margin-right: 25px;
        font-weight: 500;
    }
    .next:hover {
        color: #d24350;
    }
    .calendar_row {
        background-color: #eff0f0;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.add_buttons {
    .btn {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 7px;
        padding: 3px 8px;
    }
}

.align-star {
    top: 7px;
    right: 50px;
}

.font-col {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        color: #5c5c5c;
    }
}

//pink color border in plan
.custom-pink {
    background-color: #fbe1e1 !important;
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fbe1e1 !important;
    }
}

.custom-pink~label {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #fbe1e1 50%, #fbe1e1 100%) !important;
}

.drug-input-width {
    .ant-select-selection-search {
        width: 80% !important;
    }
}

.cursor {
    cursor: pointer;
}

.submic-pos {
    top: 4px !important;
}

 
 
 .cursor{
   cursor:pointer;
 }

 .submic-pos {
  top: 4px !important;
 }
//Dropdown arrow color
.ant-select-arrow {
  color: rgba(0, 0, 0, 0.5);
}

// .appointment_modal {
//     .ant-modal-body {
//         padding-top: 5px !important;
//     }
// }
.appointment_modal {
  .dashboard_popupdate {
      margin-bottom: 0px !important;
  }
  .ant-table-thead {
      >tr>th {
          color: #d24350;
          padding: 10px 10px;
      }
      .ant-table-cell {
          border: solid 1px rgba(0, 0, 0, 0.1);
          border-left: none;
          &:first-child {
              border-left: solid 1px rgba(0, 0, 0, 0.1);
          }
          // &.status {
          //     border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
          // }
          // &.ant-table-cell-scrollbar {
          //     border: none;
          // }
      }
  }
  .ant-table-tbody {
      >tr>td {
          padding: 10px 10px;
      }
      .ant-table-cell {
          border: solid 1px rgba(0, 0, 0, 0.1);
          border-left: none;
          border-top: none;
          &:first-child {
              border-left: solid 1px rgba(0, 0, 0, 0.1);
          }
      }
  }
  .appoint_list_container {
      max-height: 500px;
      overflow-y: scroll;
  }
  .dashboard_search_image {
      position: absolute;
      top: 8px;
      right: 5px;
  }
}

.hospital-card {
  .dashboard-hospital-card {
      position: relative;
      .total_appts_button {
          position: relative;
          z-index: 99;
          .cus_pad {
              padding: 2px 6px;
              font-weight: 500;
          }
          .bold_appts {
              font-weight: 700;
              font-size: 2.1875rem;
          }
      }
      a {
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          z-index: 1
      }
      .min-height {
          min-height: 30px;
      }
      .no-style {
          border: none;
          background: none;
      }
  }
}