.floating-vc {
    position: fixed;
    bottom: 5%;
    right: 1.2%;
    border: 1px solid #b8b8b8;
    background-color: #ffffff;
    width: 40%;
    height: 200px;
    z-index: 900;
    box-shadow: 0 0 4px 0 #ababab;
}
.floating-vc.hidden {
    display: none;
}
.fvc-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.half-wh-elem {
    width: 100%;
    height: 100%;
}

.floating-vc .fvc-widget {
    background: url('../images/electronics.svg') #DFDFDF center no-repeat;
    background-size: 5vw;
}

.floating-vc .fvc-widget.publisher-elem {
    /* top: 1% !important;
    right: 1%;
    width: 100px; */
    /* position: absolute; */
    /* width: 50%; */
    background-color: #383838;
    box-shadow: none;
    border: none;
    background: url('../images/electronics.svg') #ebebeb no-repeat center;
    background-size: 5vw;
    /* background-image: url('/assets/images/publisher-poster.png');
    background-repeat: no-repeat;
    background-position: 0 20%;
    background-size: cover; */
    border-radius: 0;
}

.floating-vc .video-call-widget-vffull {
    /* background: url('/images/electronics.svg') #DFDFDF center no-repeat;
    background-size: 100px; */
    /* background-image: url('/assets/images/publisher-poster.png');
    background-repeat: no-repeat;
    background-position: 0 30%;
    background-size: cover; */
}
.fvc-widget {
    float: left;
    width: 50%;
    height: 100%;
}

.fvc-widget:first-child {
    border-right: 1px solid #fff;
}

.vc-back-btn {
    /* background-color: #ffffff;
    border-radius: 100px;
    border: 2px solid #ccc; */
}
.vc-back-btn img {
    width: 2.3vw;
}

#js-video-endcall-to-patient.hidden {
  display: none !important;
}
#js-video-call-to-patient.hidden {
  display: none !important;
}
#floating_vc_dialog_drag {
    cursor: all-scroll;
}
.end-video-call-to-patient{
    width: 12rem;
}




.full-wh-elem {
    width: 100%;
    height: 100%;
}

.publisher-elem .full-wh-elem {
    width: 100%;
    height: auto;
}

.publisher-elem {
    top: 6% !important;
    right: 5%;
    /* width: 95px;
    height: 77px; */
    width: calc(198px / 2);
    height: calc(135px / 2);
    /* border: 1px solid #a2a2a2; */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 6px 0.3px rgba(0, 0, 0, 0.3);
    border: solid 1px rgba(32, 32, 32, 0.3);
    /* background-color: #ffffff; */
    /* background-image: url('/assets/images/publisher-poster.png'); */
    background: url('../images/electronics.svg') #ebebeb no-repeat center;
    background-size: 2vw;
    /* background-repeat: no-repeat;
    background-position: 0 20%; */
    /* background-size: cover; */
    /* width: calc(198px / 2);
    height: calc(160px / 2); */
    /* border-radius: 10px;
    box-shadow: 2.2px 5.6px 12.7px 0.3px rgba(0, 0, 0, 0.42);
    border: solid 2px rgba(32, 32, 32, 0.39);
    background-color: #ffffff; */
}

.hidden {
    display: none;
}

.btn-call:hover {
    color: #d24350;
}

.call-message {
    line-height: 38px;
    color: #d24350;
}

.doctor-video {
    /* height: 100%; */
}

.video-call-widget {
    background: url('../images/electronics.svg') #DFDFDF center no-repeat;
    background-size: 100px;
    /* background-image: url('/assets/images/publisher-poster.png'); */
    /* background-repeat: no-repeat;
    background-position: 0 30%;
    background-size: cover; */
    /* border: 1px solid #a2a2a2; */
    border: solid 1px #d8d8d8;
    /* min-height: 310px;
    height: 370px; */
    /* height: calc(600px / 2); */
    height: 290px;
    margin-bottom: 15px;
}

.call-controls {
    position: absolute;
    padding: 5px;
    bottom: 0;
    width: 100%;
}

/* button.control-btn[title="Mute Microphone"] {
    visibility: hidden;
}

button.control-btn[title="End Call"] {
    visibility: hidden;
} */

.video-call-widget:hover .call-controls button.control-btn[title="Mute Microphone"] {
    visibility: visible;
}

.video-call-widget:hover .call-controls button.control-btn[title="End Call"] {
    visibility: visible;
}

.video-call-widget:hover .call-controls {
    /* background-color: #383838;
    opacity: 0.5; */
    /* background-image: url('/assets/images/overlay.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; */
    background-color: #e6e6e6;
}

.control-btn {
    border-radius: 100px;
    background: transparent;
    border: none;
    padding: 5px 6px;
    margin-right: 5px;
}

.control-btn:last-child {
    margin-right: 0;
}

.control-btn.active,
.control-btn:hover {
    /* background-color: #ffffff;
    border: 1px solid #a2a2a2; */
    /* padding: 4px 5px; */
}

/* .control-btn img {
    width: 30px;
} */

/* Modal css */

.modal-main-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* width: 88% !important;
    height: 100% !important; */
    background-color: #383838 !important;
    border: 1px solid #252526 !important;
    border-radius: 0 !important;
    z-index: 11;
}

.modal-container {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
}

.main_video_modal {
    width: 65%;
    height: 100%;
}

.publisher-elem-modal {
    bottom: -6px;
    right: 0;
    width: 234px;
    background-color: #383838;
}

.modal-call-controls {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 10px;
}

.video-recording {
    position: absolute;
    top: 2%;
    left: 2%;
    z-index: 1;
}

.red-icon {
    background-color: #f00;
    border-radius: 100px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin: 0 5px 0 0;
}

.end-ong-call {
    font-size: 1vw;
    font-weight: 500;
    border: 2px solid #d24350;
    height: 40px;
}

.end-ong-call img {
    width: 1.6vw;
}

.end-ong-call:hover {
    background-color: #ffffff;
}

.control-btn img {
    width: 2.3vw;
}

/* .video-btn > .img-btn {
    height: 43px !important;
    font-size: 1.3vw !important;
    width: 16vw !important;
} */
.end-ong-call {
    height: 43px;
    font-size: 1.3vw;
    width: 18vw;
}


